import uuidv4 from 'uuid/v4'
import { currentTime } from 'utils/date'

// local imports
import {
  inspirationsCollection,
  inspirationsScheduledCollection,
  storageRef
} from './firebase'

export const getAll = async () => {
  const snapshot = await inspirationsCollection.get()
  const inspirations = []
  snapshot.forEach(inspiration => {
    inspirations.push({ ...inspiration.data(), id: inspiration.id })
  })
  return inspirations
}

export const getAllScheduled = async () => {
  const snapshot = await inspirationsScheduledCollection.get()
  const inspirations = []
  snapshot.forEach(inspiration => {
    inspirations.push({ ...inspiration.data(), id: inspiration.id })
  })
  return inspirations
}

export const add = async (
  user,
  { title, text, plainText, publishDate, imageFile }
) => {
  if (!user) {
    throw Error('Invalid member')
  }
  const { imageLocation, imageURL } = await uploadImage(user, imageFile)
  const payload = {
    title,
    text,
    plainText,
    publishDate,
    imageLocation,
    imageURL
  }
  await inspirationsScheduledCollection.add(payload)
}

export const edit = async (
  user,
  { currentInspiration, title, text, plainText, publishDate }
) => {
  if (!user) {
    throw Error('Invalid member')
  }
  const payload = {
    title,
    text,
    plainText,
    publishDate,
    imageLocation: currentInspiration.imageLocation,
    imageURL: currentInspiration.imageURL
  }

  if (publishDate > currentTime()) {
    await inspirationsCollection.doc(currentInspiration.id).delete()
    await inspirationsScheduledCollection.doc(currentInspiration.id).delete()
    await inspirationsScheduledCollection.add(payload)
  } else {
    await inspirationsCollection.doc(currentInspiration.id).update(payload)
  }
}

export const remove = async ({ id, imageLocation }) => {
  if (imageLocation) {
    const fileRef = storageRef.child(imageLocation)
    await fileRef.delete()
  }
  await inspirationsCollection.doc(id).delete()
  await inspirationsScheduledCollection.doc(id).delete()
}

export const uploadImage = async (user, file) => {
  const imageLocation = `inspirations/${user.uid}/${uuidv4()}`
  const fileRef = storageRef.child(imageLocation)
  await fileRef.put(file)
  const imageURL = await fileRef.getDownloadURL()
  return { imageLocation, imageURL }
}
