import React, { useState, useEffect } from 'react'
import {
  Container,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core'
import { orderBy } from 'lodash'
// root imports
import { LoadingOverlay, Status } from 'components'
import { audioClassesCollection, audioClassesScheduledCollection, storageRef } from 'services/firebase'
import { AUDIO_CLASSES_TABLE_HEADERS, INVERT_DIRECTION } from 'constants/index'
import { getAudioClasses } from 'services/AudioClasses'

// local imports
import { AddDialog, AudioClassesList, ManageCategories } from './components'
import useScheduledAudioClasses from '../../hooks/useScheduledAudioClasses'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2)
  },
  root: {
    width: '100%'
  },
  deleteIcon: {
    cursor: 'pointer'
  },
  editIcon: {
    cursor: 'pointer'
  },
  listAction: {
    display: 'flex'
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  button: {
    marginBottom: theme.spacing(2)
  }
}))

const AudioClasses = () => {
  const [audioClasses, setAudioClasses] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [sortDirection, setSortDirection] = useState('desc')
  const [columnToSort, setColumnToSort] = useState('')
  const {
    scheduledAudioClasses,
    loading: scheduledACLoading,
    sortDirection: scheduledACSortDirection,
    getAll: getScheduledAC,
    orderBy: orderScheduledACBy,
  } = useScheduledAudioClasses()

  const classes = useStyles()

  function fetchAudioClasses() {
    try {
      setLoading(true)
      getAudioClasses(setAudioClasses, setLoading)
      getScheduledAC()
    } finally {
    }
  }

  useEffect(() => {
    fetchAudioClasses()
    return // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteAudioClass = async (id, filename = null, scheduled = false) => {
    try {
      const fileRef = storageRef.child(`audio-classes/${filename}`)
      await fileRef.delete()
    } catch (e) {
      // pass
    } finally {
      const collection = scheduled ? audioClassesScheduledCollection : audioClassesCollection
      collection.doc(id).delete()
      setStatus(`${scheduled ? 'Scheduled ' : ''} Audio class deleted`)
      fetchAudioClasses()
    }
  }

  const handleAddAudioClass = () => {
    setStatus('Audio class scheduled')
    fetchAudioClasses()
  }

  const handleOnUpdateAudioClass = () => {
    setStatus('Audio class updated')
    fetchAudioClasses()
  }

  const handleSort = columnName => {
    setColumnToSort(columnName)
    setSortDirection(
      columnToSort === columnName ? INVERT_DIRECTION[sortDirection] : 'asc'
    )
  }

  const sortedAudioClasses = orderBy(audioClasses, columnToSort, sortDirection)

  return (
    <Container maxWidth='xl' className={classes.container}>
      {loading && scheduledACLoading && <LoadingOverlay />}
      <div className={classes.actions}>
        <AddDialog onAdd={handleAddAudioClass} />
        <ManageCategories />
      </div>
      <Card className={classes.root}>
        <CardHeader title='Scheduled Audio Classes' />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {AUDIO_CLASSES_TABLE_HEADERS.map(header => (
                <TableCell
                  className={classes.th}
                  align={
                    header.slug === 'name' || header.slug === 'teams'
                      ? 'left'
                      : 'right'
                  }
                  key={header.slug}
                  onClick={() => orderScheduledACBy(header.slug)}
                  sortDirection={scheduledACSortDirection}
                >
                  <TableSortLabel
                    active={columnToSort === header.slug}
                    direction={scheduledACSortDirection}
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AudioClassesList
              audioClasses={scheduledAudioClasses}
              classes={classes}
              handleDeleteAudioClass={handleDeleteAudioClass}
              handleOnUpdateAudioClass={handleOnUpdateAudioClass}
              loading={scheduledACLoading}
              scheduled={true}
            />
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.root} style={{ marginTop: '40px' }}>
        <CardHeader title='Published Audio Classes' />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {AUDIO_CLASSES_TABLE_HEADERS.map(header => (
                <TableCell
                  className={classes.th}
                  align={
                    header.slug === 'name' || header.slug === 'teams'
                      ? 'left'
                      : 'right'
                  }
                  key={header.slug}
                  onClick={() => handleSort(header.slug)}
                  sortDirection={sortDirection}
                >
                  <TableSortLabel
                    active={columnToSort === header.slug}
                    direction={sortDirection}
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AudioClassesList
              audioClasses={sortedAudioClasses}
              classes={classes}
              handleDeleteAudioClass={handleDeleteAudioClass}
              handleOnUpdateAudioClass={handleOnUpdateAudioClass}
              loading={loading}
            />
          </TableBody>
        </Table>
        <Status message={status} onClose={() => setStatus('')} />
      </Card>
    </Container>
  )
}

export default AudioClasses
