import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  makeStyles,
  Box
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import * as yup from 'yup'
import 'react-quill/dist/quill.snow.css'

import { LoadingOverlay } from 'components'
import { storageRef, liveClassesCollection } from 'services/firebase'
import { trimObject } from 'utils/trim'
import { changeDateTimeZone } from 'utils/date'
import { LIVE_CLASS_AUDIO_ROOM_URL } from 'constants/index'

import Admins from './Admins'
import ImageButton from './ImageButton'
import StartTime from './StartTime'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  fab: {
    marginBottom: theme.spacing(2)
  },
  formControlEmail: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 300
  },
  formControlRole: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 143
  },
  editor: {
    marginTop: '10px',
    width: '100%'
  },
  fileButtonContainer: {
    width: 146
  },
  fileButton: {
    margin: `${theme.spacing(1)}px 0`
  },
  fileInput: {
    display: 'none'
  },
  image: {
    width: '200px',
    margin: `${theme.spacing(1)}px 0`
  },
  adminsContainer: {
    marginTop: theme.spacing(1),
    border: '1px solid #ccc',
    padding: theme.spacing(2)
  },
  startTime: {
    marginTop: theme.spacing(2),
    width: 240
  },
  timezone: {
    marginTop: theme.spacing(2),
    width: 240
  }
}))

const initialDescription = 'This a sample plain text description'

const initialValues = {
  name: '',
  description: initialDescription,
  admins: [],
  audioRoomURL: LIVE_CLASS_AUDIO_ROOM_URL,
  isComplete: false,
  reminderUsers: [],
  listenerCount: 0
}

const AddDialog = ({ onAdd }) => {
  const classes = useStyles()

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({ file: null, preview: null })
  const [selectedDate, handleDateChange] = useState(new Date())
  const [selectedTimeZone, setSelectedTimeZone] = useState('')

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = e => {
    const { name, value } = e.target

    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleChangeTimezone = ({ value }) => {
    setSelectedTimeZone(value)
  }

  const handleAddAdmin = newAdmin => {
    setValues(prev => ({
      ...prev,
      admins: [...prev.admins, newAdmin]
    }))
  }

  const handleDeleteAdmin = (id, setErrors) => {
    setValues(prev => ({
      ...prev,
      admins: values.admins.filter(({ userId }) => userId !== id)
    }))
    setErrors({ email: '', role: '' })
  }

  const resetValues = () => {
    setValues(initialValues)
    setImage({ file: null, preview: null })
    handleDateChange(new Date())
    setSelectedTimeZone('')
  }

  const saveToDB = async imageURL => {
    try {
      await liveClassesCollection.add({
        ...trimObject(values),
        startTime: changeDateTimeZone(selectedDate, selectedTimeZone),
        imageURL
      })
      onAdd()
      toggleDialog()
    } finally {
      setLoading(false)
      resetValues()
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    setLoading(true)

    const imageUpload = storageRef
      .child(`live-classes/${image.file.name}`)
      .put(image.file)

    imageUpload.on(
      'state_changed',
      snapshot => {},
      error => {},
      async () => {
        const imageURL = await imageUpload.snapshot.ref.getDownloadURL()
        saveToDB(imageURL)
      }
    )
  }

  const handleImageChange = ({ target }) => {
    const reader = new FileReader()
    const file = target.files[0]

    reader.onloadend = () => {
      const preview = reader.result
      setImage({ file, preview })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const isNameValid = yup
    .string()
    .required()
    .isValidSync(values.name)

  const isDescriptionValid = yup
    .string()
    .required()
    .test('is-valid', v => v !== initialDescription)
    .isValidSync(values.description)

  const isImageFileValid = !!image.file

  const isAdminsValid =
    values.admins.length && values.admins.some(({ role }) => role === 'host')

  const isStartTimeValid = yup
    .string()
    .required()
    .isValidSync(selectedDate)

  const isTimeZoneValid = yup
    .string()
    .required()
    .isValidSync(selectedTimeZone)

  const canSubmit =
    isNameValid &&
    isDescriptionValid &&
    isImageFileValid &&
    isAdminsValid &&
    isStartTimeValid &&
    isTimeZoneValid

  return (
    <>
      {loading && <LoadingOverlay />}

      <Fab
        color='primary'
        onClick={toggleDialog}
        aria-label='Add'
        className={classes.fab}
        size='small'
      >
        <AddIcon />
      </Fab>

      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>New Live Audio Class</DialogTitle>

        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              name='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange}
            />

            <TextField
              fullWidth
              disabled={loading}
              required
              multiline
              rows={6}
              name='description'
              label='Plain Text Description'
              value={values.description}
              type='textfield'
              margin='normal'
              onChange={handleChange}
            />

            <ImageButton
              image={image}
              classes={classes}
              loading={loading}
              onImageChange={handleImageChange}
            />

            <Admins
              classes={classes}
              loading={loading}
              admins={values.admins}
              onAddAdmin={handleAddAdmin}
              onDeleteAdmin={handleDeleteAdmin}
            />

            <StartTime
              classes={classes}
              loading={loading}
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              onChangeTimezone={handleChangeTimezone}
              isDateChanged={true}
            />

            <Box className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Schedule Live Audio Class
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AddDialog
