import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { firebaseConfig } from '../config'

firebase.initializeApp(firebaseConfig)

// firebase

const storageRef = firebase.storage().ref()

const fireAuth = firebase.auth()

// collections

const audioClassesCollection = firebase.firestore().collection('audio-classes')
const audioClassesScheduledCollection = firebase
  .firestore()
  .collection('audio-classes-scheduled')
const liveClassesCollection = firebase.firestore().collection('live-classes')
const inspirationsCollection = firebase.firestore().collection('inspirations')
const inspirationsScheduledCollection = firebase
  .firestore()
  .collection('inspirations-scheduled')
const teamsCollection = firebase.firestore().collection('teams')
const usersCollection = firebase.firestore().collection('users')
const subscriptionsCollection = firebase.firestore().collection('subscriptions')
const membersSpaceSubscriptionsCollection = firebase
  .firestore()
  .collection('active-subscriptions')
const userMonthAndGoalTotalsCollection = firebase
  .firestore()
  .collection('userMonthAndGoalTotals')
const coachesCollection = firebase.firestore().collection('coaches')
const categoriesCollection = firebase
  .firestore()
  .collection('audio-classes-categories')
const chargifySubsCollection = firebase
  .firestore()
  .collection('chargify-subscriptions')
const statsCollection = firebase.firestore().collection('stats')
const pushNotificationsCollection = firebase
  .firestore()
  .collection('push-notifications')
const notificationContentCollection = firebase
  .firestore()
  .collection('notificationContent')

export {
  storageRef,
  fireAuth,
  audioClassesCollection,
  audioClassesScheduledCollection,
  liveClassesCollection,
  inspirationsCollection,
  inspirationsScheduledCollection,
  teamsCollection,
  usersCollection,
  subscriptionsCollection,
  membersSpaceSubscriptionsCollection,
  userMonthAndGoalTotalsCollection,
  coachesCollection,
  categoriesCollection,
  chargifySubsCollection,
  statsCollection,
  pushNotificationsCollection,
  notificationContentCollection
}
