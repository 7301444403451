import React from 'react'
import { TableCell } from '@material-ui/core'

import { startCase } from 'lodash'

const AdminsCell = ({ admins }) => {
  const { name, email } = admins.find(({ role }) => role === 'host')

  return (
    <>
      <TableCell>
        {startCase(name)}
        <br />
        {email}
      </TableCell>
    </>
  )
}

export default AdminsCell
