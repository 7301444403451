import { useState } from 'react'
import loadashOrderBy from 'lodash/orderBy'
import { getScheduledLiveClasses } from 'services/LiveClasses'
import { INVERT_DIRECTION } from 'constants/index'

const useScheduledLiveClasses = () => {
  const [loading, setLoading] = useState(true)
  const [scheduledLiveClasses, setScheduledLiveClasses] = useState([])
  const [sortDirection, setSortDirection] = useState('desc')
  const [columnToSort, setColumnToSort] = useState('')

  const getAll = async () => {
    setLoading(true)
    const result = await getScheduledLiveClasses()
    setScheduledLiveClasses(result)
    setLoading(false)
  }

  const orderBy = column => {
    setLoading(true)
    const newSortDirection =
      columnToSort === column ? INVERT_DIRECTION[sortDirection] : 'asc'
    const sorted = loadashOrderBy(
      scheduledLiveClasses,
      column,
      newSortDirection
    )
    setScheduledLiveClasses(sorted)
    setColumnToSort(column)
    setSortDirection(newSortDirection)
    setLoading(false)
  }

  return {
    loading,
    sortDirection,
    scheduledLiveClasses,
    getAll,
    orderBy
  }
}

export default useScheduledLiveClasses
