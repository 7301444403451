import React from 'react'
import { Link, TableCell, TableRow, CircularProgress } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { startCase } from 'lodash'

// root imports
import { formatDate } from 'utils/date'

import { EditClass } from '../'

const AudioClassesList = ({
  audioClasses,
  classes,
  loading,
  handleDeleteAudioClass,
  handleOnUpdateAudioClass,
  scheduled
}) => (
  <React.Fragment>
    {audioClasses.length > 0 &&
      audioClasses.map(row => (
        <TableRow key={row.id}>
          <TableCell component='th' scope='row'>
            <Link href={row.url} target='_blank' rel='noopener noreferrer'>
              {row.name}
            </Link>
          </TableCell>
          <TableCell
            align='right'
            style={{
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {row.desc}
          </TableCell>
          <TableCell align='right'>{row.coach}</TableCell>
          <TableCell align='right'>{row.coachNew}</TableCell>
          <TableCell align='right'>{startCase(row.type)}</TableCell>
          <TableCell align='right'>{row.category}</TableCell>
          <TableCell align='right'>{formatDate(row.date)}</TableCell>
          <TableCell align='right' className={classes.listAction}>
            <div
              onClick={() => handleDeleteAudioClass(row.id, row.filename, scheduled)}
              className={classes.deleteIcon}
            >
              <DeleteIcon />
            </div>
            <div className={classes.editIcon}>
              <EditClass onUpdate={handleOnUpdateAudioClass} audioClass={row} scheduled={scheduled} />
            </div>
          </TableCell>
        </TableRow>
      ))}
    {loading && (
      <TableRow>
        <TableCell colSpan={5}>
          <CircularProgress size={14} thickness={4} /> Loading...
        </TableCell>
      </TableRow>
    )}
  </React.Fragment>
)

export default AudioClassesList
