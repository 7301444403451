import React from 'react'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  IconButton,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Link,
  makeStyles
} from '@material-ui/core'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowDownward,
  ArrowUpward
} from '@material-ui/icons'
import { Link as RouterLink, matchPath } from 'react-router-dom'
// root imports
import { LoadingOverlay } from 'components'
import { AppContext } from 'contexts'
import { formatDate } from 'utils/date'
import { trimObject } from 'utils/trim'
import { Inspirations } from 'services'
import * as routes from 'routes'

// local imports
import { AddForm } from './components'
import { View } from './components'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  root: {
    width: '100%'
  },
  deleteCell: {
    cursor: 'pointer'
  }
}))

const InspirationsContainer = ({ history, location }) => {
  const [inspirations, setInspirations] = React.useState([])
  const [scheduledInspirations, setScheduledInspirations] = React.useState([])
  const [currentInspiration, setCurrentInspiration] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [sortOrder, setSortOrder] = React.useState('asc')
  const [sortColumn, setSortColumn] = React.useState('name')
  const appContext = React.useContext(AppContext)

  const classes = useStyles()

  const getInspirations = React.useCallback(async () => {
    try {
      setIsLoading(true)
      setInspirations(await Inspirations.getAll())
      setScheduledInspirations(await Inspirations.getAllScheduled())
    } finally {
      setIsLoading(false)
    }
  }, [])

  React.useEffect(() => {
    getInspirations()
  }, [getInspirations])

  const handleAddInspiration = async payload => {
    try {
      setIsLoading(true)
      const trimmedPayload = trimObject(payload)
      await Inspirations.add(appContext.user, trimmedPayload)
      history.push(routes.inspirations())
      getInspirations()
    } finally {
      setIsLoading(false)
    }
  }
  const handleEditInspiration = async payload => {
    try {
      setIsLoading(true)
      await Inspirations.edit(appContext.user, payload)
      history.push(routes.inspirations())
      getInspirations()
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteInspiration = async inspiration => {
    try {
      setIsLoading(true)
      await Inspirations.remove(inspiration)
      getInspirations()
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    history.push(routes.inspirations())
    setCurrentInspiration(null)
  }

  const isAdding = !!matchPath(location.pathname, {
    path: routes.addInspiration()
  })

  const isViewing = !!matchPath(location.pathname, {
    path: routes.viewInspiration()
  })

  const isEditing = !!matchPath(location.pathname, {
    path: routes.editInspiration()
  })

  if (isViewing && !currentInspiration) {
    history.push(routes.inspirations())
    return null
  }

  switch (sortColumn) {
    case 'date':
      inspirations.sort((a, b) => {
        if (sortOrder === 'asc') return a.date - b.date
        else return b.publishDate - a.publishDate
      })
      break
    case 'name':
      inspirations.sort((a, b) => {
        if (sortOrder === 'asc') return a.title.localeCompare(b.title)
        else return b.title.localeCompare(a.title)
      })
      break
    default:
      break
  }
  return (
    <Container maxWidth='xl' className={classes.container}>
      {isLoading && <LoadingOverlay />}
      <Fab
        color='primary'
        size='small'
        className={classes.actions}
        component={RouterLink}
        to={routes.addInspiration()}
      >
        <AddIcon />
      </Fab>

      <Card className={classes.root}>
        <CardHeader title='Inspirations' />
        <Table>
          <colgroup>
            <col style={{ width: '60%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                Title
                <IconButton
                  onClick={() => {
                    sortOrder === 'asc'
                      ? setSortOrder('desc')
                      : setSortOrder('asc')
                    if (sortColumn !== 'name') {
                      setSortColumn('name')
                      setSortOrder('desc')
                    }
                  }}
                >
                  {sortOrder === 'desc' && sortColumn === 'name' ? (
                    <ArrowDownward style={{ fontSize: '1.1rem' }} />
                  ) : (
                    <ArrowUpward style={{ fontSize: '1.1rem' }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                Date Added
                <IconButton
                  onClick={() => {
                    sortOrder === 'asc'
                      ? setSortOrder('desc')
                      : setSortOrder('asc')
                    setSortColumn('date')
                    if (sortColumn !== 'date') {
                      setSortColumn('date')
                      setSortOrder('desc')
                    }
                  }}
                >
                  {sortOrder === 'desc' && sortColumn === 'date' ? (
                    <ArrowDownward style={{ fontSize: '1.1rem' }} />
                  ) : (
                    <ArrowUpward style={{ fontSize: '1.1rem' }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledInspirations.map((inspiration, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={routes.viewInspiration()}
                    onClick={() => setCurrentInspiration(inspiration)}
                  >
                    {inspiration.title}
                  </Link>
                </TableCell>
                <TableCell>
                  {formatDate(inspiration.publishDate)} (Scheduled)
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    onClick={() => {
                      setCurrentInspiration(inspiration)
                    }}
                    size='small'
                    component={RouterLink}
                    to={routes.editInspiration()}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteInspiration(inspiration)}
                    size='small'
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {inspirations.map((inspiration, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={routes.viewInspiration()}
                    onClick={() => setCurrentInspiration(inspiration)}
                  >
                    {inspiration.title}
                  </Link>
                </TableCell>
                <TableCell>{formatDate(inspiration.publishDate)}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    onClick={() => {
                      setCurrentInspiration(inspiration)
                    }}
                    size='small'
                    component={RouterLink}
                    to={routes.editInspiration()}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteInspiration(inspiration)}
                    size='small'
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {inspirations.length === 0 && isLoading && (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress size={14} thickness={4} /> Loading...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>

      <Dialog open={isAdding || isViewing || isEditing} onClose={handleClose}>
        <DialogTitle>
          {(isAdding || !currentInspiration) && 'New Inspiration'}
          {(isViewing || isEditing) &&
            currentInspiration &&
            currentInspiration.title}
        </DialogTitle>
        <DialogContent>
          {(isAdding || isEditing) && (
            <AddForm
              isSubmiting={isLoading}
              onCancel={handleClose}
              onSave={handleAddInspiration}
              currentInspiration={currentInspiration}
              onEdit={handleEditInspiration}
            />
          )}
          {isViewing && <View inspiration={currentInspiration} />}
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default InspirationsContainer
