import { startCase } from 'lodash'

import { chargifySubsCollection, usersCollection } from './firebase'
import { USERS_QUERY_LIMIT, SUBSCRIPTION_PLATFORM } from '../constants'
import { getActiveSubscriptions } from './Subscriptions'
import { isAnniversary } from 'utils/date'

// initial fetch of users
async function getUsers(
  columnToSort = 'lastWalkingDate',
  sortDirection = 'desc',
  searchField = 'email',
  searchTerm = null
) {
  try {
    let initialQuery = usersCollection

    let orderBy = getOrderBy(columnToSort)

    let query = null

    if (searchTerm) {
      query = initialQuery
        .orderBy(searchField)
        .startAt(searchTerm)
        .endAt(searchTerm + '\uf8ff')
        .limit(USERS_QUERY_LIMIT)
    } else {
      query = initialQuery
        .orderBy(orderBy, sortDirection)
        .limit(USERS_QUERY_LIMIT)
    }

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    let lastVisibleDoc =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]

    let usersData = formatUsers(documentData)

    return { usersData, lastVisibleDoc }
  } catch (error) {
    console.log('fetchUsers error: ', error)
  }
}

// fetch more users
async function getMoreUsers(
  columnToSort = 'lastWalkingDate',
  sortDirection = 'desc',
  lastDoc
) {
  try {
    let initialQuery = usersCollection

    let orderBy = getOrderBy(columnToSort)

    let query = initialQuery
      .orderBy(orderBy, sortDirection)
      .startAfter(lastDoc)
      .limit(USERS_QUERY_LIMIT)

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    let lastVisibleDoc =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]

    let usersData = formatUsers(documentData)

    return { usersData, lastVisibleDoc }
  } catch (error) {
    console.log('getMoreUsers error: ', error)
  }
}

// fetch all users
async function getAllUsers() {
  try {
    const documentSnapshots = await usersCollection.get()

    const usersData = documentSnapshots.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    return usersData
  } catch (error) {
    console.log('getAllUsers error: ', error)
  }
}

async function getTeamMembers(teamId) {
  async function fetchTeamMembers(teamId) {
    try {
      const teamMembers = []
      const usersSnapshot = await usersCollection
        .where('teamIds', 'array-contains', teamId)
        .get()
      usersSnapshot.forEach(doc => {
        const docData = doc.data()
        teamMembers.push({ ...docData, id: doc.id })
      })

      return teamMembers
    } catch (error) {
      console.log('fetchTeamMembers error: ', error)
    }
  }

  const users = await fetchTeamMembers(teamId)

  return users
}

// get users who completed their monthly goal
async function getGoalCrushers() {
  try {
    const query = usersCollection.where('hasHitGoalThisMonth', '==', true)

    let documentSnapshots = await query.get()

    let goalCrushers = documentSnapshots.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    return goalCrushers
  } catch (error) {
    console.log('getGoalCrushers error: ', error)
  }
}

async function getNoBraceletSubscriberEmails() {
  try {
    const query = chargifySubsCollection
      .where('subscriptionName', '==', '8.99 No Bracelet Option')
    
    let snapshots = await query.get()
    let emails = snapshots.docs.map(snapshot => snapshot.get('email'))
    return emails
  } catch (error) {
    console.log('getNoBraceletSubscriberEmails error: ', error)
  }
}

// get members who are reaching their 1 year anniversary
async function getMemberAnniversary(month) {
  try {
    let allUsers = await getAllUsers()
    const activeSubscriptions = await getActiveSubscriptions(month)

    allUsers = allUsers.filter(u => isAnniversary(u.createdOn, month))

    const activeMembersAnniversaries = allUsers.filter(u =>
      activeSubscriptions.includes(u.email)
    )

    return activeMembersAnniversaries
  } catch (error) {
    console.log('getMemberAnniversary error: ', error)
  }
}

function getOrderBy(column) {
  let orderBy = ''
  switch (column) {
    case 'name':
      orderBy = 'name'
      break
    case 'email':
      orderBy = 'email'
      break
    case 'monthGoal':
      orderBy = 'monthGoal'
      break
    case 'distance':
      orderBy = 'monthTotal'
      break
    case 'goalCompleted':
      orderBy = 'hasHitGoalThisMonth'
      break
    case 'teams':
      orderBy = 'teamIds'
      break
    case 'paymentPlatform':
      orderBy = 'paymentPlatform'
      break
    case 'subscriptionState':
      orderBy = 'subscriptionState'
      break
    case 'shippingStatus':
      orderBy = 'shippingStatus'
      break
    default:
      orderBy = 'lastWalkingDate'
  }

  return orderBy
}

function formatUsers(docs) {
  let users = docs.map(doc => ({
    id: doc.id,
    name: startCase(doc.name),
    email: doc.email && doc.email.toLowerCase(),
    lastWalkingDate: doc.lastWalkingDate,
    monthGoal: doc.monthGoal || 0,
    distance: doc.monthTotal || 0,
    goalCompleted: doc.hasHitGoalThisMonth,
    paymentPlatform: SUBSCRIPTION_PLATFORM[doc.subscriptionPlatform],
    subscriptionState: doc.subscriptionState,
    subscriptionExpirationDate: doc.subscriptionExpirationDate,
    teamIds: doc.teamIds,
    shippingStatus: !!doc.shippingStatus,
    comments: doc.comments || '',
    fullAddress: {
      address: doc.address,
      city: doc.city,
      state: doc.state,
      zip: doc.zip
    }
  }))

  return users
}

async function setShippingStatus(userId, status) {
  await usersCollection.doc(userId).update({ shippingStatus: status })
}

async function setUserAddress(userId, address) {
  await usersCollection.doc(userId).update({ ...address })
}

async function updateComments(userId, comments) {
  await usersCollection.doc(userId).update({ comments })
}

async function getUserById(id) {
  try {
    const snapshot = await usersCollection.doc(id).get()
    const user = snapshot.data() ? formatUsers([snapshot.data()])[0] : null
    return user
  } catch (error) {
    console.error(`getUserById error: `, error)
    return null
  }
}

export {
  getUsers,
  getMoreUsers,
  getGoalCrushers,
  getNoBraceletSubscriberEmails,
  getMemberAnniversary,
  getAllUsers,
  getTeamMembers,
  setShippingStatus,
  setUserAddress,
  updateComments,
  getUserById
}
