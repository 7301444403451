import React, { useState, useRef, useCallback } from 'react'
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

import ActionMenu from '@material-ui/icons/MoreVert'
import { CSVLink } from 'react-csv'
import { filter, includes, map } from 'lodash'

// local imports
import {
  getAllUsers,
  getGoalCrushers,
  getNoBraceletSubscriberEmails,
  setShippingStatus,
  getMemberAnniversary
} from 'services/Users'
import { LoadingOverlay } from 'components'
import miles from 'utils/miles'
import { formatDate } from 'utils/date'
import { SUBSCRIPTION_PLATFORM } from 'constants/index'

export default function ActionsMenu({
  comments: { showComments, setShowComments },
  allUsers,
  setAllUsers
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [goalCrushers, setGoalCrushers] = useState([])
  const [activeMembersAnniversary, setActiveMembersAnniversary] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anniversaryDialogOpen, setAnniversaryDialogOpen] = useState(false)
  const [anniversaryMonth, setAnniversaryMonth] = useState(null)

  const useStyles = makeStyles(theme => ({
    itemText: {
      fontSize: 14
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    }
  }))

  const classes = useStyles()

  const usersLinkRef = useRef(null)
  const goalCrushersRef = useRef(null)
  const activeMembersAnniversaryRef = useRef(null)

  const handleUsersDownload = async () => {
    setLoading(true)
    const allUsers = await getAllUsers()
    const usersData = map(filter(allUsers, u => !!u.email))
    const formattedUsers = formatUsers(usersData)
    setUsers(formattedUsers)
    setLoading(false)
    users &&
      usersLinkRef &&
      usersLinkRef.current &&
      usersLinkRef.current.link.click()
  }

  const handleGoalCrushersDownload = async shipping => {
    setLoading(true)
    const goalCrushersData = await getGoalCrushers()
    const noBraceletSubscriberEmails = await getNoBraceletSubscriberEmails()

    const formattedGoalCrushers = formatGoalCrushers(goalCrushersData, noBraceletSubscriberEmails)
    setGoalCrushers(formattedGoalCrushers)
    if (shipping) {
      let threadCount = 0
      goalCrushersData.forEach(async goalCrusher => {
        await setShippingStatus(goalCrusher.id, true)
        threadCount++

        for (let u of allUsers) {
          if (u.id === goalCrusher.id) {
            u.shippingStatus = true
            break
          }
        }
        if (threadCount === goalCrushersData.length) {
          setAllUsers(allUsers.slice(0, allUsers.length - 1))
          setLoading(false)
        }
      })
    }

    setLoading(false)
    goalCrushers &&
      goalCrushersRef &&
      goalCrushersRef.current &&
      goalCrushersRef.current.link.click()
  }

  const handleActiveMembersAnniversaryDownload = async () => {
    setLoading(true)
    const activeMembersAnniversaryData = await getMemberAnniversary(anniversaryMonth)

    const formattedActiveMembersAnniversary = formatGoalCrushers(activeMembersAnniversaryData)
    setActiveMembersAnniversary(formattedActiveMembersAnniversary)

    setLoading(false)
    activeMembersAnniversary &&
      activeMembersAnniversaryRef &&
      activeMembersAnniversaryRef.current &&
      activeMembersAnniversaryRef.current.link.click()
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleAnniversaryMonthChange = event => {
    setAnniversaryMonth(event.target.value)
  }

  const openPopover = Boolean(anchorEl)

  const formatUsers = useCallback(u => {
    let formattedUsers = u.map(user => ({
      name: user.name,
      email: user.email,
      address: user.address,
      city: user.city,
      state: user.state,
      zip: user.zip,
      country: user.country,
      personalGoal: user.monthGoal ? miles(user.monthGoal) + ' mi' : '',
      distanceWalked: user.monthTotal ? miles(user.monthTotal) + ' mi' : '',
      goalCompleted: user.goalCompleted ? 'Yes' : 'No',
      paymentPlatform: SUBSCRIPTION_PLATFORM[user.subscriptionPlatform],
      subscriptionState: user.subscriptionState,
      shippingStatus: user.shippingStatus ? 'Shipped' : 'Not Shipped'
    }))

    return formattedUsers
  }, [])

  const formatGoalCrushers = useCallback((goalCrushers, noBraceletSubscriberEmails) => {
    
    const isNoBraceletSubscriberEmail = email => includes(noBraceletSubscriberEmails, email)

    let formattedGoalCrushers = goalCrushers.map(user => ({
      name: user.name && user.name.toUpperCase(),
      email: user.email && user.email.toUpperCase(),
      address: isNoBraceletSubscriberEmail(user.email) ? 
        "NO BRACELET SUBSCRIPTION - DON'T SHIP" : 
        user.address && user.address.toUpperCase(),
      city: user.city && user.city.toUpperCase(),
      state: user.state && user.state.toUpperCase(),
      zip: user.zip && formatZipCode(user.zip.toUpperCase()),
      registrationDate: formatDate(user.createdOn/1000),
      shipped: user.shippingStatus ? 'Yes' : 'No'
    }))

    return formattedGoalCrushers
  }, [])

  const formatZipCode = zip => (zip.charAt(0) === "0")
      ? "'" + zip
      : zip

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Button onClick={handlePopoverOpen}>
        <ActionMenu />
      </Button>
      <Popover
        id='more-menu'
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List component='nav' aria-label='more-actions'>
          {users && (
            <CSVLink
              ref={usersLinkRef}
              filename='users.csv'
              data={users}
              style={{ display: 'none' }}
            >
              Export Members
            </CSVLink>
          )}
          <ListItem button onClick={handleUsersDownload}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary='Export Members'
            />
          </ListItem>
          {goalCrushers && (
            <CSVLink
              ref={goalCrushersRef}
              filename='goal-crushers.csv'
              data={goalCrushers}
              style={{ display: 'none' }}
            >
              Export Goal Crushers
            </CSVLink>
          )}
          <ListItem button onClick={() => setDialogOpen(true)}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary='Export Goal Crushers'
            />
          </ListItem>
          {activeMembersAnniversary && (
            <CSVLink
              ref={activeMembersAnniversaryRef}
              filename='active-members-anniversary.csv'
              data={activeMembersAnniversary}
              style={{ display: 'none' }}
            >
              Export Active Members Anniversary
            </CSVLink>
          )}
          <ListItem button onClick={() => setAnniversaryDialogOpen(true)}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary='Export Active Members Anniversary'
            />
          </ListItem>
          <ListItem button onClick={() => setShowComments(v => !v)}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary={showComments ? 'Hide Comments' : 'Show Comments'}
            />
          </ListItem>
        </List>
      </Popover>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Goal Crushers Shipped Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to mark all goal crushers shipped status to true?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogOpen(false)
              handleGoalCrushersDownload(false)
            }}
            color='primary'
          >
            No
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false)
              handleGoalCrushersDownload(true)
            }}
            color='primary'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={anniversaryDialogOpen}
        onClose={() => setAnniversaryDialogOpen(false)}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Anniversary Month
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select month</InputLabel>
              <Select
                labelId="month-select-label"
                id="month-select"
                value={anniversaryMonth}
                onChange={handleAnniversaryMonthChange}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setAnniversaryDialogOpen(false)
              handleActiveMembersAnniversaryDownload()
            }}
            color='primary'
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
