import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const Loader = () => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default Loader
