import lscache from 'lscache'
import { usersCollection } from './firebase'
import { unix } from '../utils/date'

function getWalkingSessions(snapshots) {
  const wsData = []
  snapshots.forEach(snap => {
    let userId = ''
    let walksDocData = []
    snap.forEach(walksDoc => {
      let walkingSession = walksDoc.data()
      userId = walkingSession.userId
      const startTime = unix(walkingSession.startTime)
      if (!isNaN(walkingSession.distance)) {
        walksDocData.push({
          userId,
          distance: walkingSession.distance || 0,
          startTime
        })
      }
    })
    wsData[userId] = walksDocData
  })

  return wsData
}

// fetch all user's walking sessions
function fetchDistance(users) {
  const promises = []
  users.forEach(user => {
    const ws = usersCollection.doc(user.id).collection('walkingSessions').get()
    promises.push(ws)
  })
  Promise.all(promises).then(snapshots => {
    const ws = getWalkingSessions(snapshots)
    const userList = users.map(user => {
      user.walkingSessions = ws[user.id] || undefined
      return user
    })

    // cache users for 24 hours
    lscache.set('users', JSON.stringify(userList), 1440)
  })
  .catch(e => {
    console.log('Promise.all error: ', e)
  })
}

export default fetchDistance