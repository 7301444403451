import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  makeStyles
} from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import * as yup from 'yup'
import { startCase } from 'lodash'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// root imports
import { LoadingOverlay } from 'components'
import {
  audioClassesCollection,
  audioClassesScheduledCollection,
  coachesCollection,
  categoriesCollection
} from 'services/firebase'
import { trimObject } from 'utils/trim'
import { editorModules } from 'constants/index'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  menu: {
    width: 200
  },
  input: {
    display: 'none'
  },
  fab: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minWidth: 400
  },
  editor: {
    marginTop: '10px',
    width: '100%'
  }
}))

const classTypes = [
  {
    value: 'meditation',
    label: 'Meditation'
  },
  {
    value: 'podcast',
    label: 'Podcast'
  },
  {
    value: 'exercise',
    label: 'Exercise'
  },
  {
    value: 'book',
    label: 'Book'
  },
  {
    value: 'joyful wanderer',
    label: 'Joyful Wanderer'
  }
]

const EditClass = ({ onUpdate, audioClass, scheduled }) => {
  const initialValues = {
    name: audioClass.name || '',
    coachId: audioClass.coachId || '',
    categoryId: audioClass.categoryId || '',
    type: audioClass.type,
    coach: audioClass.coach,
    desc: audioClass.desc,
    plainDesc: audioClass.plainDesc,
    version: 2
  }

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [coaches, setCoaches] = useState([])
  const [categories, setCategories] = useState([])

  const classes = useStyles()

  async function getCoaches() {
    try {
      setLoading(true)
      let coachesArr = []
      const coachesSnapshot = await coachesCollection
        .orderBy('name', 'asc')
        .get()
      coachesSnapshot.forEach(coach =>
        coachesArr.push({ ...coach.data(), id: coach.id })
      )
      setCoaches(coachesArr)
    } finally {
      setLoading(false)
    }
  }

  async function getCategories(type) {
    if (type) {
      try {
        setLoading(true)
        let categoriesArr = []
        const categoriesSnapshot = await categoriesCollection
          .where('type', '==', type)
          .get()
        categoriesSnapshot.forEach(cat =>
          categoriesArr.push({ id: cat.id, ...cat.data() })
        )
        setCategories(categoriesArr)
      } finally {
        setLoading(false)
      }
    }
  }

  React.useEffect(() => {
    getCoaches()
    getCategories(values.type)
  }, [values.type])

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = name => e => {
    setValues({ ...values, [name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const collection = scheduled
        ? audioClassesScheduledCollection
        : audioClassesCollection
      const coach = coaches.filter(coach => coach.id === values.coachId)
      await collection.doc(audioClass.id).update({
        ...trimObject(values),
        coach: coach[0].name
      })
      onUpdate()
      toggleDialog()
    } finally {
      setLoading(false)
    }
  }

  const isCoachValid = yup
    .string()
    .required()
    .isValidSync(values.coachId)

  const isCategoryValid = yup
    .string()
    .required()
    .isValidSync(values.categoryId)

  const isTypeValid = yup
    .string()
    .required()
    .isValidSync(values.type)

  const canSubmit = isCoachValid && isCategoryValid && isTypeValid

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <EditIcon onClick={toggleDialog} />
      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>Update Audio Class</DialogTitle>
        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              id='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange('name')}
            />
            <FormControl className={classes.formControl} required>
              <InputLabel id='select-coach-label'>Coach</InputLabel>
              <Select
                labelId='select-coach-label'
                id='coach-select'
                value={values.coachId}
                displayEmpty
                onChange={handleChange('coachId')}
              >
                {coaches &&
                  coaches.map(coach => (
                    <MenuItem key={coach.id} value={coach.id}>
                      {startCase(coach.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} required>
              <FormLabel id='editor-label'>Description</FormLabel>
              <div id='editor-container'>
                <ReactQuill
                  labelId='editor-label'
                  className={classes.editor}
                  modules={editorModules}
                  theme='snow'
                  value={values.desc}
                  onChange={v => setValues({ ...values, desc: v })}
                  bounds='#editor-container'
                />
              </div>
            </FormControl>
            <TextField
              fullWidth
              disabled={loading}
              required
              multiline
              rows={6}
              id='plainDesc'
              label='Plain Text Description'
              value={values.plainDesc}
              type='textfield'
              margin='normal'
              onChange={handleChange('plainDesc')}
            />
            <TextField
              fullWidth
              disabled={loading}
              required
              id='type'
              select
              label='Select type'
              value={values.type}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              helperText='Please select the class type.'
              margin='normal'
              onChange={handleChange('type')}
            >
              {classTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {values.type && (
              <FormControl className={classes.formControl} required>
                <InputLabel id='select-coach-label'>Category</InputLabel>
                <Select
                  labelId='select-category-label'
                  id='category-select'
                  value={values.categoryId}
                  displayEmpty
                  onChange={handleChange('categoryId')}
                >
                  {categories &&
                    categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {startCase(category.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <div className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Update
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default EditClass
