import moment from 'moment'
import { tz } from 'moment-timezone'
import { DATE_PLURAL } from 'constants/index'

export const getStartOfDate = (
  startOfDate,
  range = 'month',
  dateDirection = null
) => {
  if (dateDirection) {
    if (dateDirection.direction === 'previous')
      startOfDate.subtract(1, DATE_PLURAL[range])
    if (dateDirection.direction === 'next')
      startOfDate.add(1, DATE_PLURAL[range])
  }

  if (range === 'week') startOfDate.add(1, 'days')

  return startOfDate
}

export const getEndOfDate = (
  endOfDate,
  range = 'month',
  dateDirection = null
) => {
  if (dateDirection) {
    if (dateDirection.direction === 'previous')
      endOfDate.subtract(1, DATE_PLURAL[range])
    if (dateDirection.direction === 'next') endOfDate.add(1, DATE_PLURAL[range])
  }

  if (range === 'week') endOfDate.add(1, 'days')

  return endOfDate
}

export const formatDate = timestamp => {
  const date = moment.unix(timestamp)
  return date.format('ddd, MMM D YYYY')
}

export const formatDateWithTime = timestamp => {
  const date = moment.unix(timestamp)
  return date.format('ddd, MMM D YYYY HH:mm a')
}

export const unix = timestamp => {
  return moment(timestamp).valueOf() * 1000
}

export const toUnix = date => {
  return moment(date).unix()
}

export const currentTime = () => {
  return Math.ceil(new Date().getTime() / 1000)
}

export const daysDifferences = date => {
  date = moment(date)
  const today = moment()

  return moment.duration(today.diff(date)).asDays()
}

export const isAnniversary = (date, month) => {
  if (!date || !month) {
    return false
  }

  const subscriptionStartDate = moment(date)
  const lastYear = moment()
    .subtract(1, 'year')
    .format('YYYY')

  return subscriptionStartDate.format('YYYY') === lastYear &&
    subscriptionStartDate.format('M') === month.toString()
    ? true
    : false
}

// All country codes can be retrieved using method tz.countries()
export const getTimeZones = country => {
  if (country) {
    return tz.zonesForCountry(country)
  }
  return tz.names()
}

export const changeDateTimeZone = (date, timezone) => {
  const stringWithoutTimeZone = moment(date).format('YYYY-MM-DD HH:mm:ss')
  return moment.tz(stringWithoutTimeZone, timezone).toDate()
}
