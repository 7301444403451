import React from 'react'
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { coachesCollection } from 'services/firebase'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  titleBar: {
    background: 'rgba(0,0,0,0.2)'
  },
  icon: {
    color: 'white'
  }
}))

const ImagesList = ({ coach: { id, imageUrls } }) => {
  const classes = useStyles()

  const deleteImage = async (id, image, index) => {
    // filter selected image
    const images = imageUrls.filter(i => i !== image)

    // update DB with new set of images
    await coachesCollection.doc(id).update({
      imageUrls: images
    })

    // remove image from DOM
    const element = document.getElementById(`${id}-${index}`)
    element.parentNode.removeChild(element)
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={3.5} cellHeight={206}>
        {imageUrls.map((image, index) => (
          <GridListTile id={`${id}-${index}`} key={`${id}-${index}`}>
            <img src={image} alt='' />
            <GridListTileBar
              classes={{
                root: classes.titleBar
              }}
              actionIcon={
                <IconButton
                  onClick={e => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete this image?'
                      )
                    )
                      deleteImage(id, image, index)
                  }}
                >
                  <DeleteIcon
                    className={classes.icon}
                    aria-label='Delete Image'
                  />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}

export default ImagesList
