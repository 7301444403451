import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  TextField,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { LoadingOverlay } from 'components'

// root imports
import {
  getPushNotificationContent,
  updateNotificationContent
} from 'services/PushNotifications'

const NotificationContent = ({ id }) => {
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(true)
  const [updated, setUpdated] = useState(false)

  const useStyles = makeStyles(theme => ({
    heading: {
      marginBottom: theme.spacing(3)
    },
    textField: {
      marginBottom: theme.spacing(3),
      width: '100%',
      '&:last-child': {
        marginBottom: 0
      }
    },
    actions: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    alert: {
      marginLeft: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0
    }
  }))

  const classes = useStyles()

  // get notification content
  const getContentData = async docId => {
    setLoading(true)
    const data = await getPushNotificationContent(docId)
    if (data) setContent(Object.entries(data))
    setLoading(false)
  }

  useEffect(() => {
    getContentData(id)
  }, [id])

  const handleChange = e => {
    setUpdated(false)
    const updatedContent = content.map(field => {
      if (field[0] === e.target.name) field[1] = e.target.value

      return field
    })

    setContent(updatedContent)
  }

  const setContentData = async () => {
    const newContent = Object.fromEntries(content)
    await updateNotificationContent(id, newContent)
    setUpdated(true)
    getContentData(id)
  }

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      {content.length > 0 && (
        <React.Fragment>
          <CardContent>
            {content.length > 0
              ? content.map(([key, value]) => (
                  <TextField
                    className={classes.textField}
                    variant='outlined'
                    key={key}
                    name={key}
                    label={key.toUpperCase()}
                    value={value}
                    onChange={handleChange}
                  />
                ))
              : null}
          </CardContent>
          <CardActions disableSpacing className={classes.actions}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setContentData()}
            >
              Update
            </Button>

            {updated && (
              <Alert
                severity='success'
                className={classes.alert}
                onClose={() => setUpdated(false)}
              >
                Notification content updated!
              </Alert>
            )}
          </CardActions>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default NotificationContent
