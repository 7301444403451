import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import * as yup from 'yup'

// root imports
import { FileUpload, LoadingOverlay } from 'components'
import { coachesCollection, storageRef } from 'services/firebase'
import { asyncForEach } from 'utils/asyncForEach'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  menu: {
    width: 200
  },
  input: {
    display: 'none'
  },
  upload: {
    marginTop: theme.spacing(2)
  },
  fab: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  fileUpload: {
    margin: '10px 0'
  },
  edit: {
    cursor: 'pointer'
  }
}))

const EditCoach = ({ onEdit, coach }) => {
  const fileUploadRef = React.useRef(null)

  const initialValues = {
    name: coach.name,
    imageUrls: coach.imageUrls,
    images: []
  }

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = name => e => {
    setValues({ ...values, [name]: e.target.value })
  }

  const saveToDB = async imageUrls => {
    try {
      await coachesCollection.doc(coach.id).set({
        name: values.name,
        imageUrls: [...values.imageUrls, ...imageUrls]
      })
      toggleDialog()
      onEdit()
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const imagesPath = []

    // upload images
    if (values.images && values.images.length > 0) {
      uploadImages(values.images)
        .then(async imagesRef => {
          await asyncForEach(imagesRef, async snapshot => {
            let url = await snapshot.ref.getDownloadURL()
            imagesPath.push(url)
          })

          saveToDB(imagesPath)
        })
        .catch(error => {
          console.log('error', error)
        })
    } else {
      saveToDB(imagesPath)
    }
  }

  const uploadImages = images =>
    Promise.all(
      images.map(image =>
        storageRef.child(`coaches/${values.name}-${image.name}`).put(image)
      )
    )

  const isNameValid = yup
    .string()
    .required()
    .isValidSync(values.name)

  const canSubmit = isNameValid

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <EditIcon onClick={toggleDialog} className={classes.edit} />
      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>Edit Coach</DialogTitle>
        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              id='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange('name')}
            />
            <Typography
              variant='caption'
              color='textSecondary'
              style={{ marginBottom: -5, marginTop: 5 }}
            >
              Add New Image(s)
            </Typography>
            <FileUpload
              labelIdle={
                'Drag & drop your images or ' +
                '<span class="filepond--label-action"> Browse </span>'
              }
              disabled={loading}
              allowMultiple={true}
              acceptedFileTypes={['image/jpeg', 'image/png']}
              imageValidateSizeMinWidth='696'
              imageValidateSizeMaxWidth='696'
              imageValidateSizeMinHeight='369'
              imageValidateSizeMaxHeight='369'
              maxFileSize='200KB'
              ref={fileUploadRef}
              files={values.images}
              className={classes.fileUpload}
              onupdatefiles={fileItems => {
                setValues(values => ({
                  ...values,
                  images: fileItems.map(fileItem => fileItem.file)
                }))
              }}
              onremovefile={(e, file) =>
                setValues(values => ({
                  ...values,
                  images: values.images.filter(f => f === file)
                }))
              }
            />
            <div className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default EditCoach
