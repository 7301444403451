import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

// root imports
import { getTeamMembers } from 'services/Users'
import miles from 'utils/miles'

function TableContent({ creatorId, member, classes }) {
  const { id, name, email, isAdmin, monthGoal, monthTotal } = member
  const isCreator = id === creatorId
  const style = clsx(classes.cell, { [classes.row]: isCreator })
  const customName = `${name} (${isCreator ? 'creator, ' : ''}${email})${isAdmin ? ' [Admin]' : ''}`

  return (
    <TableRow >
      <TableCell className={classes.pad} />
      <TableCell className={style}>
        {customName}
      </TableCell>
      <TableCell className={style}>
        {miles(monthGoal)}
      </TableCell>
      <TableCell className={style}>
        {miles(monthTotal)}
      </TableCell>
      <TableCell className={style} />
    </TableRow >
  )
}

function Members({ teamId, creatorId }) {
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(true)

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: '#eee'
    },
    cell: {
      width: '25%'
    },
    pad: {
      minWidth: '42px'
    },
    row: {
      fontWeight: 'bold'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    async function getMembers(teamId) {
      try {
        setLoading(true)
        const teamMembers = await getTeamMembers(teamId)
        setMembers(teamMembers)
      } finally {
        setLoading(false)
      }
    }

    getMembers(teamId)
  }, [teamId])

  return (
    <Table className={classes.root}>
      <TableBody>
        {!loading &&
          members.length > 0 &&
          members.map((member, index) =>
            <TableContent
              key={index}
              member={member}
              creatorId={creatorId}
              classes={classes}
            />
          )}
        {!loading && members.length === 0 && (
          <TableRow>
            <TableCell>No team members</TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow>
            <TableCell>
              <CircularProgress size={14} thickness={4} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default Members
