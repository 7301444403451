import React, { useState } from 'react'
import {
  FormControl,
  Grid,
  Typography,
  Card,
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  makeStyles
} from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'

// root imports
import { login } from 'api'
import { fireAuth, usersCollection } from 'services/firebase'
import { Loader } from 'components'
import * as routes from 'routes'

const LogIn = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  document.title = 'Login | Admin Portal'

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    try {
      const loginResponse = await login(email, password)
      const token = loginResponse.result
      const auth = await fireAuth.signInWithCustomToken(token)
      if (auth.user) {
        usersCollection
          .where('email', '==', auth.user.email)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              if (doc.data().isAdmin) {
                history.push(routes.app())
              } else {
                setIsLoading(false)
                setError('User is not an admin.')
                fireAuth.signOut()
                return
              }
            })
          })
      }
    } catch (error) {
      setError('Invalid credentials')
    } finally {
      setIsLoading(false)
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      maxWidth: '400px',
      minWidth: '350px'
    }
  }))

  const classes = useStyles()

  const Error = () => (
    <React.Fragment>
      <Typography variant='body1' color='error' align='center'>
        {error}
      </Typography>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
        style={{ minHeight: '80vh' }}
      >
        <Grid item>
          <Card className={classes.root}>
            <Box p={2}>
              <Typography variant='h4' component='h1' align='center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z' />
                </svg>
              </Typography>
              {error && <Error />}
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Box m={1}>
                    <TextField
                      autoFocus
                      fullWidth
                      variant='outlined'
                      type='email'
                      label='Email'
                      onChange={handleEmailChange}
                    />
                  </Box>
                  <Box m={1}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      type={showPassword ? 'text' : 'password'}
                      label='Password'
                      value={password}
                      onChange={handlePasswordChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              aria-label='Toggle password visibility'
                              onClick={handleShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  <Box m={1}>
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Log In
                    </Button>
                  </Box>
                </FormControl>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default LogIn
