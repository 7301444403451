import React, { useEffect, useState } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import moment from 'moment'

// root imports
import { getUserMonthTotalsByEmail } from 'services/UserMonthGoalAndTotals'
import { AddressModal } from 'components'
import { LoadingOverlay } from 'components'
import miles from 'utils/miles'

const HistoricUserRow = ({ user, selectedDate }) => {
  const [monthGoal, setMonthGoal] = useState(0)
  const [monthTotal, setMonthTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const selectedMonth = moment(selectedDate).format('MMMM')
  const selectedYear = moment(selectedDate).format('YYYY')

  useEffect(() => {
    async function fetchUserMonthTotals() {
      const userMonthTotals = await getUserMonthTotalsByEmail(
        user.email,
        selectedMonth,
        selectedYear
      )
      if (userMonthTotals) {
        setMonthGoal(userMonthTotals.monthGoal || 0)
        setMonthTotal(userMonthTotals.monthTotal || 0)
      } else {
        setMonthGoal(0)
        setMonthTotal(0)
      }
      setLoading(false)
    }
    fetchUserMonthTotals()
  }, [user.email, selectedMonth, selectedYear])

  return (
    <React.Fragment>
      {loading && (
        <TableRow>
          <TableCell>
            <LoadingOverlay />
          </TableCell>
        </TableRow>
      )}
      {user ? (
        <TableRow>
          <TableCell component='th' scope='row'>
            {user.name}
          </TableCell>
          <TableCell align='right'>{user.email}</TableCell>
          <TableCell align='right'>
            <AddressModal name={user.name} address={user.fullAddress} />
          </TableCell>
          <TableCell align='right'>
            {monthGoal ? miles(monthGoal) + ' mi' : 0}
          </TableCell>
          <TableCell align='right'>
            {monthTotal ? miles(monthTotal) + ' mi' : 0}
          </TableCell>
          <TableCell align='right'>
            {monthGoal > 0 && monthTotal >= monthGoal ? 'Yes' : 'No'}
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  )
}

export default HistoricUserRow
