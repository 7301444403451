import React from 'react'
import { Modal, Button, makeStyles } from '@material-ui/core'
import { EditAddressForm } from 'components'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4)
  }
}))

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default function AddressModal({ userId, name, address, handleSetUserAddress }) {
  const [open, setOpen] = React.useState(false)
  const [openForm, setOpenForm] = React.useState(false)
  const [modalStyle] = React.useState(getModalStyle)

  const classes = useStyles()

  const handleOpen = () => {
    setOpen(true)
  }

  const showAddress = () => {
    if (address.address && address.city && address.state && address.zip)
      return true
    else return false
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const showEditModal = () => {
    handleClose()
    setOpenForm(true)
  }

  const handleSaveUserAddress = payload => {
    handleSetUserAddress(payload)
    handleCloseForm(false)
  }

  return (
    <React.Fragment>
      {showAddress() && (
        <div>
          <button type='button' onClick={handleOpen}>
            Address
          </button>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <div style={modalStyle} className={classes.paper}>
              {name.toUpperCase()}
              <br />
              {address.address.toUpperCase()}
              <br />
              {address.city.toUpperCase()}&nbsp;{address.state.toUpperCase()}
              &nbsp;&nbsp;{address.zip.toUpperCase()}
              <br /><br />
              <Button onClick={showEditModal} variant="contained" color="primary">
                Edit Address
              </Button>
            </div>
          </Modal>
          <Modal
            open={openForm}
            onClose={handleCloseForm}
          >
            <div style={modalStyle} className={classes.paper}>
              <EditAddressForm userId={userId} address={address} onCancel={handleCloseForm} onSave={handleSaveUserAddress} />
            </div>
          </Modal>
        </div>
      )}
    </React.Fragment>
  )
}
