import React from 'react'
import { Snackbar, IconButton, makeStyles } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#CCC'
  },
  close: {
    padding: theme.spacing(0.5)
  }
}))

const Status = ({ message, onClose }) => {
  const classes = useStyles()

  const handleClose = (event, reason) => {
    onClose()
  }

  return (
    <div>
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={!!message}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{message}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  )
}

export default Status
