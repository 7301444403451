import React, { useState, useEffect } from 'react'
import { startCase } from 'lodash'
import { getNameFromEmail } from 'services/Subscriptions'
import Skeleton from '@material-ui/lab/Skeleton'

export default function Name({ email }) {
  const [name, setName] = useState('')

  useEffect(() => {
    async function getName() {
      const nameFromEmail = await getNameFromEmail(email)
      setName(nameFromEmail)
    }

    getName()
  })

  return (
    <React.Fragment>
      {name ? startCase(name) : <Skeleton animation='wave' />}
    </React.Fragment>
  )
}
