import React, { createContext, useState } from 'react'

export const DistanceContext = createContext({})

export const DistanceProvider = props => {
  const { fetchedDistance: initialFetchedDistance, children } = props

  const [fetchedDistance, setFetchedDistance] = useState(initialFetchedDistance)

  const distanceContext = {
    fetchedDistance,
    setFetchedDistance
  }

  return (
    <DistanceContext.DistanceProvider value={distanceContext}>
      {children}
    </DistanceContext.DistanceProvider>
  )
}

export const { Consumer } = DistanceContext

export default DistanceContext
