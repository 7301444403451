import {
  audioClassesCollection,
  audioClassesScheduledCollection,
  coachesCollection,
  categoriesCollection
} from './firebase'

async function getAudioClasses(setAudioClasses, setLoading) {
  try {
    let query = await audioClassesCollection

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    let audioClassData = []

    documentData.forEach(async audioClass => {
      let coach = {},
        category = {}
      if (audioClass.coachId && audioClass.categoryId) {
        coach = await getCoachFromId(audioClass.coachId)
        category = await getCategoryFromId(audioClass.categoryId)
      }
      audioClassData.unshift({
        id: audioClass.id,
        coachNew: (coach && coach.name) || '',
        category: (category && category.name) || '',
        type: (category && category.type) || audioClass.type,
        ...audioClass
      })
      if (
        audioClassData.length % 30 === 0 ||
        audioClassData.length === documentData.length
      ) {
        setAudioClasses(audioClassData)
        if (audioClassData.length === documentData.length) {
          audioClassData.sort((a, b) => b.date - a.date)
          setLoading(false)
        }
      }
    })
  } catch (error) {
    console.log('getAudioClasses error: ', error)
  }
}

async function getScheduledAudioClasses() {
  try {
    let audioClassData = []
    const snapshots = await audioClassesScheduledCollection
      .orderBy('date', 'desc')
      .get()
    await Promise.all(snapshots.docs.map(async (doc) => {
      const audioClass = doc.data()
      let coach = {}
      let category = {}
      if (audioClass.coachId) {
        coach = await getCoachFromId(audioClass.coachId)
      }
      if (audioClass.categoryId) {
        category = await getCategoryFromId(audioClass.categoryId)
      }
      audioClassData.push({
        ...audioClass,
        id: doc.id,
        coachNew: (coach && coach.name) || '',
        category: (category && category.name) || '',
        type: (category && category.type) || audioClass.type
      })
    }))
    return audioClassData
  } catch (error) {
    console.log('getScheduledAudioClasses error: ', error)
  }
}

async function getCoachFromId(coachId) {
  try {
    let coachData = await coachesCollection.doc(coachId).get()
    return coachData.data()
  } catch (error) {
    console.log('getCoachFromId error: ', error)
  }
}

async function getCategoryFromId(categoryId) {
  try {
    let categoryData = await categoriesCollection.doc(categoryId).get()
    return categoryData.data()
  } catch (error) {
    console.log('getCategoryFromId error: ', error)
  }
}

export { getAudioClasses, getScheduledAudioClasses }
