import {
  pushNotificationsCollection,
  notificationContentCollection
} from './firebase'

async function getPushNotificationSettings() {
  try {
    const pushNotifications = await pushNotificationsCollection.get()

    const documentData = pushNotifications.docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    return documentData
  } catch (e) {
    console.log('getPushNotificationSettings error: ', e)
  }
}

async function togglePushNotification(id) {
  try {
    const pushNotification = await pushNotificationsCollection.doc(id).get()
    const data = pushNotification.data()

    const enabled = !data.enabled

    await pushNotificationsCollection
      .doc(id)
      .update({ enabled, timestamp: new Date() })

    return enabled
  } catch (e) {
    console.error('togglePushNotification error: ', e)
  }
}

async function getPushNotificationContent(id) {
  try {
    const notificationContent = await notificationContentCollection
      .doc(id)
      .get()

    const documentData = notificationContent.data()

    return documentData
  } catch (e) {
    console.log('getPushNotificationContent error: ', e)
  }
}

async function updateNotificationContent(id, data) {
  try {
    return await notificationContentCollection.doc(id).update(data)
  } catch (e) {
    console.error('updateNotificationContent error: ', e)
  }
}

export {
  getPushNotificationSettings,
  togglePushNotification,
  getPushNotificationContent,
  updateNotificationContent
}
