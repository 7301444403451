import React from 'react'
import { Button } from '@material-ui/core'

const ImageButton = ({ image, classes, loading, onImageChange }) => {
  return (
    <>
      <input
        accept='image/*'
        className={classes.fileInput}
        id='file-input'
        type='file'
        onChange={onImageChange}
        disabled={loading}
      />

      <label htmlFor='file-input' className={classes.fileButtonContainer}>
        <Button
          variant='contained'
          component='div'
          disabled={loading}
          className={classes.fileButton}
        >
          Select Image*
        </Button>
      </label>

      {(image.preview || image.imageURL) && (
        <img
          className={classes.image}
          alt='live'
          src={image.preview ? image.preview : image.imageURL}
        />
      )}
    </>
  )
}

export default ImageButton
