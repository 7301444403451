import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { startCase } from 'lodash'

import { LoadingOverlay } from 'components'
import { categoriesCollection } from 'services/firebase'
import { audioClassTypeList } from 'constants/index'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: 400
  },
  button: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(4),
    minWidth: 400
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  list: {
    marginBottom: theme.spacing(4)
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4)
  }
}))

function ManageCategories() {
  const [type, setType] = useState('exercise')
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [dialog, setDialog] = useState(false)
  const [input, setInput] = useState('')

  const classes = useStyles()

  const handleChange = e => {
    setType(e.target.value)
  }

  const handleInput = e => {
    setInput(e.target.value)
  }

  const saveCategory = async e => {
    e.preventDefault()
    setLoading(true)
    await categoriesCollection.add({
      name: input,
      type
    })
    setInput('')
    getCategories(type)
    setLoading(false)
  }

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  async function deleteCategory(docId) {
    await categoriesCollection.doc(docId).delete()
    getCategories(type)
  }

  async function getCategories(type) {
    try {
      setLoading(true)
      let categoriesArr = []
      const categoriesSnapshot = await categoriesCollection
        .where('type', '==', type)
        .get()
      categoriesSnapshot.forEach(cat =>
        categoriesArr.push({ id: cat.id, ...cat.data() })
      )
      setCategories(categoriesArr)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCategories(type)
  }, [type])

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={toggleDialog}
      >
        Manage Categories
      </Button>
      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>Categories</DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl className={classes.formControl}>
            <InputLabel id='select-category-label'>Type</InputLabel>
            <Select
              labelId='select-category-label'
              id='category-select'
              value={type}
              displayEmpty
              onChange={handleChange}
            >
              {audioClassTypeList.map(type => (
                <MenuItem key={type} value={type}>
                  {startCase(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.list}>
            <List>
              {categories &&
                categories.map((category, index) => (
                  <ListItem
                    key={`cat-${index}`}
                    divider={true}
                    disableGutters={true}
                  >
                    <ListItemText primary={category.name}></ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton edge='end' aria-label='delete'>
                        <DeleteIcon
                          onClick={e => {
                            if (
                              window.confirm(
                                'Are you sure you want to delete this category?'
                              )
                            )
                              deleteCategory(category.id)
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </div>
          <TextField
            label='Add new category'
            className={classes.input}
            value={input}
            onChange={e => handleInput(e)}
            InputLabelProps={{
              shrink: true
            }}
          />
          <div className={classes.action}>
            <Button
              variant='contained'
              color='primary'
              onClick={saveCategory}
              disabled={!input}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default ManageCategories
