import React from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0
  }
}))

const LoadingOverlay = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress color='primary' />
    </div>
  )
}

export default LoadingOverlay
