import React from 'react'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getTimeZones } from 'utils/date'

const StartTime = ({
  classes,
  selectedDate,
  onDateChange,
  loading,
  onChangeTimezone,
  isDateChanged
}) => {
  const timeZones = getTimeZones().map(timezone => ({
    title: timezone.replace(/_/g, ' '),
    value: timezone
  }))

  return (
    <Box display='flex' justifyContent='space-between'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          className={classes.startTime}
          value={selectedDate}
          disablePast
          onChange={onDateChange}
          label='Start Time'
          required
          disabled={loading}
        />
      </MuiPickersUtilsProvider>

      {isDateChanged && (
        <Autocomplete
          options={timeZones}
          getOptionLabel={option => option.title}
          getOptionSelected={option => option.value}
          className={classes.timezone}
          name='timezone'
          disableClearable
          required
          onChange={(_, v) => onChangeTimezone(v)}
          renderInput={params => <TextField {...params} label='Timezone*' />}
          disabled={loading}
        />
      )}
    </Box>
  )
}

export default StartTime
