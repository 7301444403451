import React from 'react'
import {
  Link,
  TableCell,
  TableRow,
  CircularProgress,
  Box
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { formatDateWithTime } from 'utils/date'

import { EditClass } from '..'
import AdminsCell from './AdminsCell'

const LiveClassesList = ({
  liveClasses,
  classes,
  loading,
  onDeleteLiveClass,
  onUpdateLiveClass,
  scheduled
}) => (
  <>
    {liveClasses.length > 0 &&
      liveClasses.map(row => (
        <TableRow key={row.id}>
          <TableCell
            component='th'
            scope='row'
            style={{
              maxWidth: '300px'
            }}
          >
            <Link
              href={row.audioRoomURL}
              target='_blank'
              rel='noopener noreferrer'
            >
              {row.name}
            </Link>
          </TableCell>

          <TableCell
            style={{
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {row.description}
          </TableCell>

          <AdminsCell admins={row.admins} />

          <TableCell>{formatDateWithTime(row.startTime.seconds)}</TableCell>

          <TableCell>
            <Box display='flex' justifyContent='center'>
              <Box style={{ cursor: 'pointer' }} mr='10px'>
                <EditClass
                  onUpdate={onUpdateLiveClass}
                  liveClass={row}
                  scheduled={scheduled}
                />
              </Box>

              <Box
                onClick={() => onDeleteLiveClass(row.id, scheduled)}
                className={classes.icon}
              >
                <DeleteIcon />
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    {loading && (
      <TableRow>
        <TableCell colSpan={5}>
          <CircularProgress size={14} thickness={4} /> Loading...
        </TableCell>
      </TableRow>
    )}
  </>
)

export default LiveClassesList
