import React from 'react'
import { MenuItem, FormControl, Select, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80
  },
  select: {
    fontSize: '12px'
  }
}))

const DistanceSelection = ({ dateRange, handleDateRangeChange }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={dateRange}
        onChange={handleDateRangeChange}
        inputProps={{
          name: 'daterange',
          id: 'date-range'
        }}
        className={classes.select}
      >
        <MenuItem value='day'>Day</MenuItem>
        <MenuItem value='week'>Week</MenuItem>
        <MenuItem value='month' selected={true}>
          Month
        </MenuItem>
        <MenuItem value='year'>Year</MenuItem>
      </Select>
    </FormControl>
  )
}

export default DistanceSelection
