import React from 'react'
import moment from 'moment'
import {
  Checkbox,
  TableRow,
  TableCell,
  CircularProgress,
  TextField
} from '@material-ui/core'
import useForceUpdate from 'use-force-update'

// root imports
import { formatDate } from 'utils/date'
import { AddressModal } from 'components'

import { TeamName } from '..'

const UsersList = ({
  users,
  loading,
  setShippingStatus,
  setUserAddress,
  getUsersData,
  updateComments,
  showComments
}) => {
  const forceUpdate = useForceUpdate()

  const handleSetShippingStatus = async (user, event) => {
    try {
      const status = event.target.checked
      await setShippingStatus(user.id, status)
      for (let u of users) {
        if (u.id === user.id) {
          u.shippingStatus = status
          break
        }
      }
      forceUpdate()
    } catch (e) {
      // pass
    }
  }

  const handleSetUserAddress = async data => {
    await setUserAddress(data.userId, data.newAddress)
    getUsersData()
  }

  return (
    <React.Fragment>
      {users.length > 0 ? (
        users.map((user, i) => {
          let subscriptionState = user.subscriptionState
          const expirationDate = user.subscriptionExpirationDate
          if (expirationDate) {
            const fromNow = moment(expirationDate).fromNow(true)
            subscriptionState += ` (expires in ${fromNow})`
          }
          return (
            <React.Fragment key={i}>
              <TableRow>
                <TableCell component='th' scope='row'>
                  {user.name}
                </TableCell>
                <TableCell align='right'>{user.email}</TableCell>
                <TableCell align='right'>
                  <AddressModal userId={user.id} name={user.name} address={user.fullAddress} handleSetUserAddress={handleSetUserAddress} />
                </TableCell>
                <TableCell align='right'>
                  {user.monthGoal ? `${user.monthGoal} mi` : '---'}
                </TableCell>
                <TableCell align='right'>
                  {user.distance ? `${user.distance} mi` : '---'}
                </TableCell>
                <TableCell align='right'>
                  {user.goalCompleted ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align='left'>
                  {user.teams && user.teams.length > 0
                    ? user.teams.map((teamId, i) => (
                        <TeamName key={i} id={teamId} />
                      ))
                    : 'None'}
                </TableCell>
                <TableCell align='right'>{user.paymentPlatform}</TableCell>
                <TableCell align='right'>{subscriptionState}</TableCell>
                <TableCell align='right'>
                  {formatDate(user.lastWalkingDate)}
                </TableCell>
                <TableCell align='right'>
                  <Checkbox
                    color='primary'
                    checked={user.shippingStatus}
                    onChange={e => handleSetShippingStatus(user, e)}
                    value='shippingStatus'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>
              </TableRow>
              {showComments && (
                <TableRow>
                  <TableCell
                    scope='row'
                    colSpan={100}
                    style={{ backgroundColor: '#fafafa' }}
                  >
                    <TextField
                      fullWidth
                      multiline
                      label='Comments'
                      margin='dense'
                      variant='outlined'
                      value={user.comments}
                      onChange={e => updateComments(user.id, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          )
        })
      ) : (
        <TableRow>
          <TableCell>
            {loading ? (
              <div>
                <CircularProgress size={14} thickness={4} /> Loading...
              </div>
            ) : (
              <div>No members found.</div>
            )}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

export default UsersList
