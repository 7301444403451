import React, { useState, useEffect } from 'react'
import { Container, Paper, makeStyles, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'

// root imports
import { getSubscriptions, getSubscriptionStats } from 'services/Subscriptions'
import { LoadingOverlay } from 'components'
import {
  SUBSCRIPTIONS_TABLE_HEADERS,
  SUBSCRIPTION_PLATFORM
} from 'constants/index'
import { formatDate, daysDifferences } from 'utils/date'
import { Name } from './components'

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({})

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    stats: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        const subscriptionsData = await getSubscriptions()
        subscriptionsData.map(
          subscription =>
            (subscription.onTrial =
              daysDifferences(subscription.subscriptionStart) >= 14
                ? 'No'
                : 'Yes')
        )
        setSubscriptions(subscriptionsData)

        const subscriptionStats = await getSubscriptionStats()
        setStats(subscriptionStats)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [])

  function columnHeaders() {
    return SUBSCRIPTIONS_TABLE_HEADERS.map(header => {
      switch (header.field) {
        case 'subscriptionStart':
          header['render'] = rowData => {
            return <FormattedDate date={rowData.subscriptionStart} />
          }
          break
        case 'subscriptionEnd':
          header['render'] = rowData => (
            <FormattedDate date={rowData.subscriptionEnd} />
          )
          break
        case 'platform':
          header['render'] = rowData => (
            <FormatPlatform platform={rowData.platform} />
          )
          break
        case 'name':
          header['render'] = rowData => <Name email={rowData.email} />
          break
        case 'onTrial':
          header['render'] = rowData => (
            <p>
              {daysDifferences(rowData.subscriptionStart) >= 14 ? 'No' : 'Yes'}
            </p>
          )
          break
        default:
          break
      }

      return header
    })
  }

  const FormattedDate = ({ date }) => (
    <p>{(date && formatDate(date / 1000)) || '-'}</p>
  )

  const FormatPlatform = ({ platform }) => (
    <p>
      {platform === 'ios' || platform === 'android'
        ? `In-App ${SUBSCRIPTION_PLATFORM[platform]}`
        : platform === 'chargify'
        ? 'Chargify'
        : '-'}
    </p>
  )
  return (
    <Container maxWidth='xl' className={classes.container}>
      {loading && <LoadingOverlay />}
      {subscriptions && !loading && (
        <>
          <div className={classes.stats}>
            <Typography>Total subscribers: {stats.totalActiveSubs}</Typography>
            <Typography>Android: {stats.activeAndroidSubs}</Typography>
            <Typography>iOS: {stats.activeIOSSubs}</Typography>
            <Typography>Chargify: {stats.activeChargifySubs}</Typography>
          </div>
        </>
      )}
      <Paper className={classes.root}>
        {subscriptions && !loading && (
          <MaterialTable
            title='Subscriptions'
            columns={columnHeaders()}
            data={subscriptions}
            options={{
              search: true,
              pageSize: 20,
              pageSizeOptions: [20, 40, 60]
            }}
            onSearchChange={e => {
              console.log('search change', e)
            }}
          />
        )}
      </Paper>
    </Container>
  )
}

export default Subscriptions
