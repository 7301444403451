import React from "react"
import { fireAuth } from '../../services/firebase'

const withAuthorization = redirectRoute => WrappedComponent => {
  class withAuthorization extends React.Component {
    componentDidMount() {
      const { history } = this.props
      fireAuth.onAuthStateChanged(user => {
        if (!user) {
          return history.push(redirectRoute)
        }
      })
    }

    render() {
      const { auth } = this.props

      if (!auth) {
        return null
      }
      return <WrappedComponent {...this.props} />
    }
  }

  return withAuthorization
}

export default withAuthorization
