import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#1C2341',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#6fffe9',
      contrastText: '#000'
    }
  }
})
