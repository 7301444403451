import React, { useState, useEffect } from 'react'
import { Container, Paper, makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'

// root imports
import { getTeams } from 'services/Teams'
import { LoadingOverlay } from 'components'
import { TEAMS_TABLE_HEADERS } from 'constants/index'

// local imports
import { Members } from './components'

function Teams() {
  const [teams, setTeams] = useState(null)
  const [loading, setLoading] = useState(false)

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        const teamsData = await getTeams()
        setTeams(teamsData)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [])

  return (
    <Container maxWidth='xl' className={classes.container}>
      {loading && <LoadingOverlay />}
      <Paper className={classes.root}>
        {teams && !loading && (
          <MaterialTable
            title='Teams'
            columns={TEAMS_TABLE_HEADERS}
            data={teams}
            options={{
              search: true,
              paging: false
            }}
            detailPanel={[
              {
                tooltip: 'Show Members',
                render: rowData => <Members teamId={rowData.id} creatorId={rowData.creatorId || null} />
              }
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        )}
      </Paper>
    </Container>
  )
}

export default Teams
