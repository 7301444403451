import React, { useState } from 'react'
import { Button, TextField, makeStyles } from '@material-ui/core'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-20px'
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}))

const EditAddressForm = ({userId, address, onCancel, onSave}) => {
  const classes = useStyles()

  const [newAddress, setNewAddress] = useState(address)

  const handleAddressChange = name => e => {
    setNewAddress({ ...newAddress, [name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSave({userId, newAddress})
  }

  const isAddressValid = yup
    .string()
    .required()
    .isValidSync(newAddress.address)

  const isCityValid = yup
    .string()
    .required()
    .isValidSync(newAddress.city)

  const isStateValid = yup
    .string()
    .required()
    .isValidSync(newAddress.state)

  const isZipValid = yup
    .string()
    .required()
    .isValidSync(newAddress.zip)

  const canSubmit =
    isAddressValid && isCityValid && isStateValid && isZipValid

  return (
    <form
      noValidate
      className={classes.form}
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        margin='normal'
        label='Address'
        value={newAddress.address}
        onChange={handleAddressChange('address')}
      />

      <TextField
        fullWidth
        margin='normal'
        label='City'
        value={newAddress.city}
        onChange={handleAddressChange('city')}
      />

      <TextField
        fullWidth
        margin='normal'
        label='State/Province'
        value={newAddress.state}
        onChange={handleAddressChange('state')}
      />

      <TextField
        fullWidth
        margin='normal'
        label='Zip/Postal Code'
        value={newAddress.zip}
        onChange={handleAddressChange('zip')}
      />

      <div className={classes.actions}>
        <Button
          variant='contained'
          type='submit'
          color='primary'
          disabled={!canSubmit}
        >
          Save Address
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default EditAddressForm
