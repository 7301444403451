import React, { useState, useRef } from 'react'
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import ActionMenu from '@material-ui/icons/MoreVert'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { filter, map } from 'lodash'

// local imports
import { getAllUsers } from 'services/Users'
import { getUserMonthTotals } from 'services/UserMonthGoalAndTotals'
import { LoadingOverlay } from 'components'

export default function ActionsMenu({ selectedDate }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const useStyles = makeStyles(theme => ({
    itemText: {
      fontSize: 14
    }
  }))

  const classes = useStyles()

  const usersTotalsLinkRef = useRef(null)

  const month = moment(selectedDate).format('MMMM')

  const year = moment(selectedDate).format('YYYY')

  const handleUsersDownload = async () => {
    setLoading(true)
    const allUsers = await getAllUsers()
    const usersData = map(filter(allUsers, u => !!u.email))
    const usersTotals = await getUserMonthTotals(usersData, month, year)
    setUsers(usersTotals)
    setLoading(false)
    users &&
      usersTotalsLinkRef &&
      usersTotalsLinkRef.current &&
      usersTotalsLinkRef.current.link.click()
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Button onClick={handlePopoverOpen}>
        <ActionMenu />
      </Button>
      <Popover
        id='more-menu'
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List component='nav' aria-label='more-actions'>
          {users && (
            <CSVLink
              ref={usersTotalsLinkRef}
              filename={`${month}-${year}-users-goals-and-totals.csv`}
              data={users}
              style={{ display: 'none' }}
            >
              Export Members
            </CSVLink>
          )}
          <ListItem button onClick={handleUsersDownload}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary={`Export ${month} ${year} as CSV`}
            />
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  )
}
