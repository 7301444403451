import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableSortLabel,
  TableRow,
  Card,
  CardHeader,
  Typography,
  makeStyles
} from '@material-ui/core'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { map, orderBy } from 'lodash'

// root imports
import {
  getUsers,
  getMoreUsers,
  setShippingStatus,
  setUserAddress,
  updateComments
} from 'services/Users'
import { getSubscriptionStats } from 'services/Subscriptions'
import {
  USERS_TABLE_HEADERS,
  INVERT_DIRECTION,
  USERS_QUERY_LIMIT
} from 'constants/index'
import miles from 'utils/miles'
import { LoadingOverlay, Search } from 'components'

// local imports
import { UsersList, ActionsMenu } from './components'

const debouncedUpdateComments = AwesomeDebouncePromise(updateComments, 500)

function Users() {
  const [users, setUsers] = useState([])
  const [lastVisible, setLastVisible] = useState(null)
  const [loading, setLoading] = useState(true)
  const [columnToSort, setColumnToSort] = useState('')
  const [sortDirection, setSortDirection] = useState('desc')
  const [showComments, setShowComments] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)
  const [searchField, setSearchField] = useState('email')
  const [stats, setStats] = useState({})

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    th: {
      cursor: 'pointer',
      alignItems: 'center'
    },
    arrows: {
      fontSize: 14,
      cursor: 'pointer'
    },
    topBar: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between'
    }
  }))

  const classes = useStyles()

  const formatUsers = useCallback(usersData => {
    let formattedUsers = usersData.map(user => {
      user.distance = miles(user.distance)
      user.monthGoal = miles(user.monthGoal)
      user.teams = user.teamIds
      return user
    })
    return formattedUsers
  }, [])

  const handleGetMoreUsers = () => {
    const getUsersData = async () => {
      setLoading(true)
      const { usersData, lastVisibleDoc } = await getMoreUsers(
        columnToSort,
        sortDirection,
        lastVisible
      )

      const userList = formatUsers(usersData)

      setLoading(false)
      setLastVisible(lastVisibleDoc)
      setUsers([...users, ...userList])
    }
    getUsersData()
  }

  // run query with sorting
  const handleSort = columnName => {
    setColumnToSort(columnName)
    setSortDirection(
      columnToSort === columnName ? INVERT_DIRECTION[sortDirection] : 'asc'
    )
  }

  const handleUpdateComments = async (userId, comments) => {
    if (comments.length > 200) {
      return
    }

    setUsers(u => map(u, e => (e.id === userId ? { ...e, comments } : e)))
    try {
      await debouncedUpdateComments(userId, comments)
    } catch (e) {
      // pass
    }
  }

  const handleSearch = e => {
    // we only want to search the database if there search term is at least 3 characters
    if (e.target.value && e.target.value.length > 2)
      setSearchTerm(e.target.value)
    else setSearchTerm(null)
  }

  const handleSearchField = e => {
    setSearchField(e.target.value)
  }

  const getUsersData = useCallback(async () => {
    setLoading(true)

    const { usersData, lastVisibleDoc } = await getUsers(
      columnToSort,
      sortDirection,
      searchField,
      searchTerm
    )

    const userList = formatUsers(usersData)

    setLoading(false)
    setLastVisible(lastVisibleDoc)
    setUsers(userList)

    const subscriptionStats = await getSubscriptionStats()
    setStats(subscriptionStats)
  }, [formatUsers, columnToSort, sortDirection, searchField, searchTerm])

  // get users on initial page load
  useEffect(() => {
    getUsersData()
  }, [getUsersData])

  const [sortedUsers, setSortedUsers] = useState(
    orderBy(users, columnToSort, sortDirection)
  )
  useEffect(() => {
    setSortedUsers(orderBy(users, columnToSort, sortDirection))
  }, [users, columnToSort, sortDirection])
  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Container maxWidth='xl' className={classes.container}>
        <div className={classes.topBar}>
          <div>
            <Typography>Total subscribers: {stats.totalActiveSubs}</Typography>
            <Typography>Android: {stats.activeAndroidSubs}</Typography>
            <Typography>iOS: {stats.activeIOSSubs}</Typography>
            <Typography>Chargify: {stats.activeChargifySubs}</Typography>
          </div>
          <div>
            <ActionsMenu
              allUsers={users}
              setAllUsers={setUsers}
              comments={{ showComments, setShowComments }}
            />
          </div>
        </div>
        <Card className={classes.root}>
          <CardHeader
            title='Members'
            action={
              <Search
                seachTerm={searchTerm}
                handleSearch={handleSearch}
                searchField={searchField}
                handleSearchField={handleSearchField}
              />
            }
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {USERS_TABLE_HEADERS.map(header => (
                  <TableCell
                    className={classes.th}
                    align={
                      header.slug === 'name' || header.slug === 'teams'
                        ? 'left'
                        : 'right'
                    }
                    key={header.slug}
                    onClick={() => handleSort(header.slug)}
                    sortDirection={sortDirection}
                  >
                    <TableSortLabel
                      active={columnToSort === header.slug}
                      direction={sortDirection}
                    >
                      {header.name}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <UsersList
                users={sortedUsers}
                loading={loading}
                getUsersData={getUsersData}
                setShippingStatus={setShippingStatus}
                setUserAddress={setUserAddress}
                updateComments={handleUpdateComments}
                showComments={showComments}
              />
            </TableBody>
            {sortedUsers && sortedUsers.length >= USERS_QUERY_LIMIT ? (
              <TableFooter>
                <TableRow>
                  <TableCell align='center' colSpan={9}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => handleGetMoreUsers()}
                    >
                      {loading ? 'Please wait...' : 'Show More'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default Users
