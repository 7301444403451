import {
  usersCollection,
  subscriptionsCollection,
  chargifySubsCollection,
  statsCollection
} from './firebase'
import { asyncForEach } from 'utils/asyncForEach'
import { SUBSCRIPTION_TYPE, SUBSCRIPTION_PLATFORM } from '../constants'
import { currentTime, toUnix } from '../utils/date'

async function fetchInAppSubscriptions() {
  try {
    let initialQuery = await subscriptionsCollection

    let query = initialQuery.orderBy('dateCreated', 'desc')

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => document.data())
    documentData = documentData.filter(u => u.email && u.expirationTime)

    let subscriptionData = []

    await asyncForEach(documentData, async sub => {
      subscriptionData.push({
        email: sub.email && sub.email.toLowerCase(),
        subscriptionStart: sub.dateCreated,
        subscriptionEnd: parseInt(sub.expirationTime),
        platform: sub.platform,
        type: SUBSCRIPTION_TYPE[sub.subscriptionId] || '-',
        state: sub.isActive
          ? `Active (${SUBSCRIPTION_PLATFORM[sub.platform]})`
          : `Expired`,
        active: sub.isActive
      })
    })

    return subscriptionData
  } catch (error) {
    console.log('fetchInAppSubscriptions error: ', error)
  }
}

async function fetchChargifySubscriptions() {
  try {
    const today = currentTime()

    let initialQuery = await chargifySubsCollection

    let query = initialQuery.orderBy('dateCreated', 'desc')

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => document.data())
    documentData = documentData.filter(u => u.email && u.expirationTime)

    let subscriptionData = []

    await asyncForEach(documentData, async sub => {
      subscriptionData.push({
        email: sub.email && sub.email.toLowerCase(),
        subscriptionStart: toUnix(sub.dateCreated) * 1000,
        subscriptionEnd: sub.expirationTime * 1000,
        platform: 'chargify',
        type: sub.subscriptionName || '-',
        state: sub.expirationTime >= today ? `Active (Chargify)` : `Expired`,
        active: sub.expirationTime >= today ? true : false
      })
    })

    return subscriptionData
  } catch (error) {
    console.log('fetchChargifySubscriptions error: ', error)
  }
}

async function getSubscriptions() {
  const inAppSubscriptions = await fetchInAppSubscriptions()
  const chargifySubscriptions = await fetchChargifySubscriptions()

  const subscriptions = [...inAppSubscriptions, ...chargifySubscriptions]

  // sort array by subscriptionStart
  subscriptions.sort((a, b) =>
    a.subscriptionStart > b.subscriptionStart ? 1 : -1
  )

  return subscriptions
}

async function getActiveInAppSubscriptions() {
  try {
    let initialQuery = await subscriptionsCollection

    let query = initialQuery.where('isActive', '==', true)

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => document.data())

    return documentData
  } catch (error) {
    console.log('getActiveInAppSubscriptions error: ', error)
  }
}

async function getActiveChargifySubscriptions() {
  try {
    const today = currentTime()
    let initialQuery = await chargifySubsCollection

    let query = initialQuery.where('expirationTime', '>=', today)

    let documentSnapshots = await query.get()

    let documentData = documentSnapshots.docs.map(document => document.data())

    return documentData
  } catch (error) {
    console.log('getActiveChargifySubscriptions error: ', error)
  }
}

async function getActiveSubscriptions() {
  try {
    let activeChargifySubscriptions = await getActiveChargifySubscriptions()

    activeChargifySubscriptions = activeChargifySubscriptions.map(u => u.email)

    let activeInAppSubscriptions = await getActiveInAppSubscriptions()

    activeInAppSubscriptions = activeInAppSubscriptions.map(u => u.email)

    let activeSubscriptions = [
      ...activeChargifySubscriptions,
      ...activeInAppSubscriptions
    ]

    return activeSubscriptions
  } catch (error) {
    console.log('getActiveSubscriptions error: ', error)
  }
}

async function getSubscriptionStats() {
  try {
    const query = await statsCollection
      .orderBy('date', 'desc')
      .limit(1)
      .get()

    const documentData = query.docs.map(document => document.data())
    return documentData[0]
  } catch (error) {
    console.log('getSubscriptionStats error: ', error)
  }
}

async function getNameFromEmail(email) {
  let usersDocumentData = await usersCollection
    .where('email', '==', email || '')
    .get()
  let usersName = await usersDocumentData.docs.map(document => document.data())
  let userName = usersName.length > 0 ? usersName[0].name : '--'
  return userName
}

export {
  getSubscriptions,
  getActiveSubscriptions,
  getNameFromEmail,
  getSubscriptionStats
}
