import path from 'path'

export const app = () => '/'

export const inspirations = () => path.join(app(), 'inspirations/')

export const addInspiration = () => path.join(inspirations(), 'add/')

export const viewInspiration = () => path.join(inspirations(), 'view/')

export const editInspiration = () => path.join(inspirations(), 'edit/')
