import { liveClassesCollection, usersCollection } from 'services/firebase'

const formatAdmin = (data, userId, role) => {
  return data.map(({ name, email, avatarURL = '' }) => ({
    userId,
    name: name.toLowerCase(),
    email,
    role,
    avatarURL
  }))[0]
}

export const getAdminByEmail = async (email, role, setFindUserLoading) => {
  try {
    setFindUserLoading(true)

    const { docs } = await usersCollection.where('email', '==', email).get()
    const userId = docs[0].id
    const data = docs.map(doc => doc.data())
    const user = data ? formatAdmin(data, userId, role) : null

    return user
  } catch (error) {
    console.error(`getUserByEmail error: `, error)
    return null
  } finally {
    setFindUserLoading(false)
  }
}

export const getLiveClasses = async (setLiveClasses, setLoading) => {
  try {
    let { docs } = await liveClassesCollection
      .where('isComplete', '==', true)
      .get()

    let documentData = docs.map(document => ({
      id: document.id,
      ...document.data()
    }))

    if (!documentData.length) {
      setLoading(false)
      setLiveClasses([])
    }

    let data = []

    documentData.forEach(doc => {
      data.push(doc)
      if (data.length % 30 === 0 || data.length === documentData.length) {
        setLiveClasses(
          data.sort((a, b) => b.startTime.seconds - a.startTime.seconds)
        )
        setLoading(false)
      }
    })
  } catch (error) {
    console.log('getLiveClasses error: ', error)
  }
}

export const getScheduledLiveClasses = async () => {
  try {
    let { docs } = await liveClassesCollection
      .where('isComplete', '==', false)
      .get()

    return docs
      .map(document => ({
        id: document.id,
        ...document.data()
      }))
      .sort((a, b) => a.startTime.seconds - b.startTime.seconds)
  } catch (error) {
    console.log('getScheduledLiveClasses error: ', error)
  }
}
