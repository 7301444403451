import React, { useState, useEffect } from 'react'
import {
  Container,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core'
import { orderBy } from 'lodash'

import { LoadingOverlay, Status } from 'components'
import { liveClassesCollection } from 'services/firebase'
import { LIVE_CLASSES_TABLE_HEADERS, INVERT_DIRECTION } from 'constants/index'
import useScheduledLiveClasses from 'hooks/useScheduledLiveClasses'
import { getLiveClasses } from 'services/LiveClasses'

import { AddDialog, LiveClassesList } from './components'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2)
  },
  root: {
    width: '100%'
  },
  header: {
    background: 'lightgray'
  },
  image: {
    width: '100px'
  },
  icon: {
    cursor: 'pointer'
  },
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  button: {
    marginBottom: theme.spacing(2)
  }
}))

const LiveClasses = () => {
  const classes = useStyles()

  const [liveClasses, setLiveClasses] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [sortDirection, setSortDirection] = useState('desc')
  const [columnToSort, setColumnToSort] = useState('')
  const {
    scheduledLiveClasses,
    loading: scheduledLCLoading,
    sortDirection: scheduledLCSortDirection,
    getAll: getScheduledLC,
    orderBy: orderScheduledLCBy
  } = useScheduledLiveClasses()

  const fetchLiveClasses = () => {
    setLoading(true)
    getLiveClasses(setLiveClasses, setLoading)
    getScheduledLC()
  }

  useEffect(() => {
    fetchLiveClasses()
    return // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteLiveClass = async (id, scheduled = false) => {
    try {
      await liveClassesCollection.doc(id).delete()
    } catch (e) {
      // pass
    } finally {
      setStatus(
        `${scheduled ? 'Scheduled' : 'Completed'} live audio class deleted`
      )
      fetchLiveClasses()
    }
  }

  const handleAddLiveClass = () => {
    setStatus('Live audio class scheduled')
    fetchLiveClasses()
  }

  const handleUpdateLiveClass = scheduled => {
    setStatus(
      `${scheduled ? 'Scheduled' : 'Completed'} live audio class updated`
    )
    fetchLiveClasses()
  }

  const handleSort = columnName => {
    setColumnToSort(columnName)
    setSortDirection(
      columnToSort === columnName ? INVERT_DIRECTION[sortDirection] : 'asc'
    )
  }

  const sortedLiveClasses = orderBy(liveClasses, columnToSort, sortDirection)

  return (
    <Container maxWidth='xl' className={classes.container}>
      {loading && scheduledLCLoading && <LoadingOverlay />}
      <div className={classes.actions}>
        <AddDialog onAdd={handleAddLiveClass} />
      </div>

      <Card className={classes.root}>
        <CardHeader title='Scheduled Live Audio Classes' />

        <Table>
          <TableHead className={classes.header}>
            <TableRow>
              {LIVE_CLASSES_TABLE_HEADERS.map(header => (
                <TableCell
                  className={classes.th}
                  key={header.slug}
                  onClick={() => orderScheduledLCBy(header.slug)}
                  sortDirection={scheduledLCSortDirection}
                >
                  <TableSortLabel
                    active={columnToSort === header.slug}
                    direction={scheduledLCSortDirection}
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align='center'>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <LiveClassesList
              liveClasses={scheduledLiveClasses}
              classes={classes}
              onDeleteLiveClass={handleDeleteLiveClass}
              onUpdateLiveClass={handleUpdateLiveClass}
              loading={scheduledLCLoading}
              scheduled={true}
            />
          </TableBody>
        </Table>
      </Card>

      <Card className={classes.root} style={{ marginTop: '40px' }}>
        <CardHeader title='Completed Live Audio Classes' />

        <Table>
          <TableHead className={classes.header}>
            <TableRow>
              {LIVE_CLASSES_TABLE_HEADERS.map(header => (
                <TableCell
                  className={classes.th}
                  key={header.slug}
                  onClick={() => handleSort(header.slug)}
                  sortDirection={sortDirection}
                >
                  <TableSortLabel
                    active={columnToSort === header.slug}
                    direction={sortDirection}
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align='center'>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <LiveClassesList
              liveClasses={sortedLiveClasses}
              classes={classes}
              onDeleteLiveClass={handleDeleteLiveClass}
              onUpdateLiveClass={handleUpdateLiveClass}
              loading={loading}
            />
          </TableBody>
        </Table>
        <Status message={status} onClose={() => setStatus('')} />
      </Card>
    </Container>
  )
}

export default LiveClasses
