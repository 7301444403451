import React, { useState } from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Divider,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useTheme
} from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Collections as CollectionsIcon,
  Face as FaceIcon,
  SettingsBackupRestore as HistoricIcon,
  Menu as MenuIcon,
  Group as GroupIcon,
  LibraryMusic as LibraryMusicIcon,
  Payment as SubscriptionIcon,
  RecordVoiceOver as CoachesIcon,
  Notifications as PushNotificationsIcon,
  Mic as MicIcon
} from '@material-ui/icons'

// root imports
import { fireAuth } from 'services/firebase'
import { Loader } from 'components'
import { devEnvironment } from 'config'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: 250
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  link: {
    padding: '8px !important'
  }
}))

const Nav = ({ user }) => {
  const [title] = useState('99Walks (Beta)')
  const [drawer, setDrawer] = useState(false)
  const [loader, setLoader] = useState(false)

  const classes = useStyles()
  const theme = useTheme()

  function ListItemLink(props) {
    return <ListItem button component='a' {...props} />
  }

  function handleLogout() {
    setLoader(true)
    fireAuth.signOut().then(() => {
      console.log('Successfully logged out!')
    })
  }

  function toggleDrawer() {
    setDrawer(open => !open)
  }

  function Authentication() {
    if (user) {
      return (
        <Button color='inherit' href='/login' onClick={handleLogout}>
          Log Out
        </Button>
      )
    } else {
      return (
        <Button color='inherit' href='/login'>
          Log In
        </Button>
      )
    }
  }

  return (
    <div className={classes.root}>
      {loader && <Loader />}
      <AppBar
        position='static'
        color='default'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawer
        })}
      >
        <Toolbar>
          {user && (
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='Menu'
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant='h6' className={classes.title}>
            {title} {devEnvironment ? '[Database: DEV]' : ''}
          </Typography>
          <Authentication />
          <Drawer open={drawer} onClose={toggleDrawer}>
            <div className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <div className={classes.drawer}>
              <List component='nav' aria-label='navigation'>
                <ListItemLink
                  to='/'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText primary='Members' />
                </ListItemLink>
                <ListItemLink
                  to='/historic-user-goals-totals'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <HistoricIcon />
                  </ListItemIcon>
                  <ListItemText primary='Historic Member Goals & Totals' />
                </ListItemLink>
                <ListItemLink
                  to='/coaches'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <CoachesIcon />
                  </ListItemIcon>
                  <ListItemText primary='Coaches' />
                </ListItemLink>
                <ListItemLink
                  to='/audio-classes'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <LibraryMusicIcon />
                  </ListItemIcon>
                  <ListItemText primary='Audio Classes' />
                </ListItemLink>
                <ListItemLink
                  to='/inspirations'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <CollectionsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Inspirations' />
                </ListItemLink>
                <ListItemLink
                  to='/teams'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary='Teams' />
                </ListItemLink>
                <ListItemLink
                  to='/subscriptions'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <SubscriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary='Subscriptions' />
                </ListItemLink>
                <ListItemLink
                  to='/push-notifications'
                  className={classes.link}
                  component={RouterLink}
                  onClick={() => setDrawer(false)}
                >
                  <ListItemIcon>
                    <PushNotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Push Notification' />
                </ListItemLink>
              </List>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Nav
