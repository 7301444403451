import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles
} from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import * as yup from 'yup'
import 'react-quill/dist/quill.snow.css'

import { LoadingOverlay } from 'components'
import { liveClassesCollection, storageRef } from 'services/firebase'
import { trimObject } from 'utils/trim'
import { changeDateTimeZone } from 'utils/date'

import Admins from '../AddDialog/Admins'
import ImageButton from '../AddDialog/ImageButton'
import StartTime from '../AddDialog/StartTime'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  formControlEmail: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 300
  },
  formControlRole: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 143
  },
  editor: {
    marginTop: '10px',
    width: '100%'
  },
  fileButtonContainer: {
    width: 146
  },
  fileButton: {
    margin: `${theme.spacing(1)}px 0`
  },
  fileInput: {
    display: 'none'
  },
  image: {
    width: '200px',
    margin: `${theme.spacing(1)}px 0`
  },
  adminsContainer: {
    marginTop: theme.spacing(1),
    border: '1px solid #ccc',
    padding: theme.spacing(2)
  },
  startTime: {
    marginTop: theme.spacing(2),
    width: 240
  },
  timezone: {
    marginTop: theme.spacing(2),
    width: 240
  }
}))

const EditClass = ({ onUpdate, liveClass, scheduled }) => {
  const classes = useStyles()

  const initialValues = {
    name: liveClass.name || '',
    description: liveClass.description || '',
    admins: liveClass.admins || [],
    startTime: new Date(liveClass.startTime.seconds * 1000) || new Date()
  }

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [selectedDate, handleDateChange] = useState(initialValues.startTime)
  const [selectedTimeZone, setSelectedTimeZone] = useState()
  const [image, setImage] = useState({
    file: null,
    preview: null,
    imageURL: liveClass.imageURL
  })

  const isDateChanged =
    selectedDate.getTime() !== liveClass.startTime.seconds * 1000

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = ({ target }) => {
    const { name, value } = target

    setValues({ ...values, [name]: value })
  }

  const handleAddAdmin = newAdmin => {
    setValues(prev => ({
      ...prev,
      admins: [...prev.admins, newAdmin]
    }))
  }

  const handleDeleteAdmin = (id, setErrors) => {
    setValues(prev => ({
      ...prev,
      admins: values.admins.filter(({ userId }) => userId !== id)
    }))
    setErrors({ email: '', role: '' })
  }

  const handleImageChange = ({ target }) => {
    const reader = new FileReader()
    const file = target.files[0]

    reader.onloadend = () => {
      const preview = reader.result
      setImage({ file, preview })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleChangeTimezone = ({ value }) => {
    setSelectedTimeZone(value)
  }

  const updateLiveClass = async imageURL => {
    try {
      await liveClassesCollection.doc(liveClass.id).update({
        ...trimObject(values),
        startTime: isDateChanged
          ? changeDateTimeZone(selectedDate, selectedTimeZone)
          : selectedDate,
        imageURL
      })
      onUpdate(scheduled)
      toggleDialog()
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    if (!!image.file) {
      const imageUpload = storageRef
        .child(`live-classes/${image.file.name}`)
        .put(image.file)

      imageUpload.on(
        'state_changed',
        snapshot => {},
        error => {},
        async () => {
          const newImageURL = await imageUpload.snapshot.ref.getDownloadURL()
          updateLiveClass(newImageURL)
        }
      )
    } else {
      updateLiveClass(image.imageURL)
    }
  }

  const isNameValid = yup
    .string()
    .required()
    .isValidSync(values.name)

  const isDescriptionValid = yup
    .string()
    .required()
    .isValidSync(values.description)

  const isImageFileValid = !!image.file || image.imageURL

  const isAdminsValid =
    values.admins.length && values.admins.some(({ role }) => role === 'host')

  const isStartTimeValid = yup
    .string()
    .required()
    .isValidSync(selectedDate)

  const isTimeZoneValid = yup
    .string()
    .required()
    .isValidSync(selectedTimeZone)

  const canSubmit =
    isNameValid &&
    isDescriptionValid &&
    isImageFileValid &&
    isAdminsValid &&
    isStartTimeValid &&
    (isTimeZoneValid || !isDateChanged)

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <EditIcon onClick={toggleDialog} />

      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>Update Live Audio Class</DialogTitle>

        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              name='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange}
            />

            <TextField
              fullWidth
              disabled={loading}
              required
              multiline
              rows={6}
              name='description'
              label='Plain Text Description'
              value={values.description}
              type='textfield'
              margin='normal'
              onChange={handleChange}
            />

            <ImageButton
              image={image}
              classes={classes}
              loading={loading}
              onImageChange={handleImageChange}
            />

            <Admins
              admins={values.admins}
              classes={classes}
              loading={loading}
              onAddAdmin={handleAddAdmin}
              onDeleteAdmin={handleDeleteAdmin}
            />

            {scheduled && (
              <StartTime
                classes={classes}
                loading={loading}
                selectedDate={selectedDate}
                onDateChange={handleDateChange}
                onChangeTimezone={handleChangeTimezone}
                isDateChanged={isDateChanged}
              />
            )}

            <div className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Update
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default EditClass
