import { teamsCollection } from './firebase'
import miles from 'utils/miles'
import { getUserById } from './Users'

async function getTeams() {
  let teamsData = []
  try {
    const teamsSnapshot = await teamsCollection.get()
    await Promise.all(teamsSnapshot.docs.map(async (doc) => {
      const docData = doc.data()
      const user = docData.creatorId ? await getUserById(docData.creatorId) : null
      teamsData.push({
        id: doc.id,
        name: user ? `${docData.name} (created by ${user.email})` : docData.name,
        goal: miles(docData.monthGoal) || 0,
        total: miles(docData.monthTotal) || 0,
        pin: docData.pin,
        creatorId: docData.creatorId
      })
    }))
    return teamsData
  } catch (error) {
    console.log(error)
  }
}

async function getTeamById(id) {
  let team = await teamsCollection.doc(id).get()
  return typeof team.data() === 'object' ? team.data() : '--'
}

export { getTeams, getTeamById }
