import { userMonthAndGoalTotalsCollection } from './firebase'
import { asyncForEach } from 'utils/asyncForEach'
import miles from 'utils/miles'

async function getUserMonthTotals(users, month, year) {
  try {
    let usersTotals = []

    await asyncForEach(users, async (user) => {
      const totals = await getUserMonthTotalsByEmail(user.email, month, year)
      usersTotals.push({
        name: user.name,
        email: user.email,
        address: user.address,
        city: user.city,
        state: user.state,
        zip: user.zip,
        country: user.country,
        personalGoal: (totals && totals.monthGoal) ? miles(totals.monthGoal) : 0,
        distanceWalked: (totals && totals.monthTotal) ? miles(totals.monthTotal) : 0,
        goalCompleted: (totals && totals.monthGoal > 0 && totals.monthTotal >= totals.monthGoal) ? 'Yes' : 'No',
      })
    })

    return usersTotals
  } catch (error) {
    console.log('getUserMonthTotals error: ', error)
  }
}

async function getUserMonthTotalsByEmail(email, month, year) {
  try {
    let initialQuery = await userMonthAndGoalTotalsCollection

    let query = initialQuery
      .doc(email)
      .collection(year)
      .doc(month)

    let documentSnapshot = await query.get()

    let userMonthTotals = documentSnapshot.data()
    
    return userMonthTotals

  } catch (error) {
    console.log('getUserMonthTotalsByEmail error: ', error)
  }
}

export { getUserMonthTotals, getUserMonthTotalsByEmail }