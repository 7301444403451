import React, { useEffect, useState } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { startCase } from 'lodash'
import { getTeamById } from 'services/Teams'

export default function TeamName({ id }) {
  const [team, setTeam] = useState('')

  useEffect(() => {
    async function getName() {
      const teamData = await getTeamById(id)
      setTeam(teamData)
    }

    getName()
  }, [id])

  return (
    <div>{team ? startCase(team.name) : <Skeleton animation='wave' />}</div>
  )
}
