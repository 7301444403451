import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import * as yup from 'yup'

// root imports
import { FileUpload, LoadingOverlay } from 'components'
import { coachesCollection, storageRef } from 'services/firebase'
import { asyncForEach } from 'utils/asyncForEach'

const useStyles = makeStyles(theme => ({
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px'
  },
  menu: {
    width: 200
  },
  input: {
    display: 'none'
  },
  upload: {
    marginTop: theme.spacing(2)
  },
  fab: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  fileUpload: {
    margin: '10px 0'
  }
}))

const initialValues = {
  name: '',
  imageUrls: [],
  images: []
}

const AddCoach = ({ onAdd }) => {
  const fileUploadRef = React.useRef(null)

  const [dialog, setDialog] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const toggleDialog = () => {
    setDialog(open => !open)
  }

  const handleChange = name => e => {
    setValues({ ...values, [name]: e.target.value })
  }

  const resetValues = () => {
    setValues(initialValues)
  }

  const saveToDB = async imageUrls => {
    try {
      await coachesCollection.add({ name: values.name, imageUrls })
      toggleDialog()
      onAdd()
    } finally {
      setLoading(false)
      resetValues()
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    // upload images
    if (values.images && values.images.length > 0) {
      uploadImages(values.images)
        .then(async imagesRef => {
          const imagesPath = []
          await asyncForEach(imagesRef, async snapshot => {
            let url = await snapshot.ref.getDownloadURL()
            imagesPath.push(url)
          })

          saveToDB(imagesPath)
        })
        .catch(error => {
          console.log('error', error)
        })
    }
  }

  const uploadImages = images => {
    const metadata = {
      cacheControl: 'max-age=31536000'
    }
    return Promise.all(
      images.map(image =>
        storageRef
          .child(`coaches/${values.name}-${image.name}`)
          .put(image, metadata)
      )
    )
  }

  const isNameValid = yup
    .string()
    .required()
    .isValidSync(values.name)

  const isImageFileValid = values.images.length > 0

  const canSubmit = isNameValid && isImageFileValid

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Fab
        color='primary'
        onClick={toggleDialog}
        aria-label='Add'
        className={classes.fab}
        size='small'
      >
        <AddIcon />
      </Fab>
      <Dialog open={dialog} onClose={toggleDialog}>
        <DialogTitle>New Coach</DialogTitle>
        <DialogContent>
          <form className={classes.form} autoComplete='off'>
            <TextField
              fullWidth
              disabled={loading}
              required
              id='name'
              label='Name'
              value={values.name}
              margin='normal'
              onChange={handleChange('name')}
            />
            <Typography
              variant='caption'
              color='textSecondary'
              style={{ marginBottom: -5, marginTop: 5 }}
            >
              Images
            </Typography>
            <FileUpload
              labelIdle={
                'Drag & drop your images or ' +
                '<span class="filepond--label-action"> Browse </span>'
              }
              disabled={loading}
              allowMultiple={true}
              acceptedFileTypes={['image/jpeg', 'image/png']}
              imageValidateSizeMinWidth='696'
              imageValidateSizeMaxWidth='696'
              imageValidateSizeMinHeight='369'
              imageValidateSizeMaxHeight='369'
              maxFileSize='200KB'
              ref={fileUploadRef}
              files={values.images}
              className={classes.fileUpload}
              onupdatefiles={fileItems => {
                setValues(values => ({
                  ...values,
                  images: fileItems.map(fileItem => fileItem.file)
                }))
              }}
              onremovefile={(e, file) =>
                setValues(values => ({
                  ...values,
                  images: values.images.filter(f => f === file)
                }))
              }
            />
            <div className={classes.actions}>
              <Button onClick={toggleDialog} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !canSubmit}
              >
                Add Coach
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default AddCoach
