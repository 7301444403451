import React from 'react'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1)
    },
    '& > p': {
      whiteSpace: 'pre-line'
    },
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%'
  }
}))

const View = ({ inspiration }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div dangerouslySetInnerHTML={{ __html: inspiration.text }}></div>
      <img
        className={classes.image}
        src={inspiration.imageURL}
        alt={inspiration.title}
      />
    </div>
  )
}

export default View
