import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Link,
  RadioGroup,
  Radio,
  FormControl
} from '@material-ui/core'
import useStyles from './styles'
// import { getNameFromEmail } from 'services/Subscriptions'

const Payment = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('')
  const [sameBillingAddress, setSameBillingAddress] = useState(true)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [enableCoupon, setEnableCoupon] = useState(false)
  const [paymentType, setPaymentType] = useState('paypal')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [joinTitle] = useState('99 Walks Monthly Subscription')
  // document.title = '99Walks | Admin Portal'
  const classes = useStyles()
  const [error, setError] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    // const nameFromEmail = await getNameFromEmail(email)
    if (password === confirmPassword) {
      console.log('cool')
    } else {
      setError(true)
      console.log('Test')
    }
  }

  const addressForm = () => (
    <Grid
      item
      container
      xs={12}
    >
      <Grid item container >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='First-Name'
            label='First Name'
            autoComplete='First-Name'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='Last-Name'
            label='Last Name'
            autoComplete='Last-Name'
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id='street-address'
          label='Street Address'
          autoComplete='street-address'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id='address-line'
          label='Apt., suite, unit, building, floor, etc.'
          autoComplete='address-line'
        />
      </Grid>
      <Grid item container >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='city'
            label='City'
            autoComplete='city'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='state'
            label='State'
            autoComplete='state'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='postal-code'
            label='Postal Code'
            autoComplete='postal-code'
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id='country'
          label='Country'
          autoComplete='country'
        />
      </Grid>
    </Grid>
  )

  const paymentForm = () => (
    <Grid item container >
      <Grid item xs={12}>
        <TextField
          fullWidth
          id='credit-card'
          label='Credit Card'
          autoComplete='credit-card'
        />
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <FormLabel>Expiration</FormLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='expiration-month'
            label='Month'
            autoComplete='expiration-month'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='expiration-year'
            label='Year'
            autoComplete='expiration-year'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='security-code'
            label='Security Code'
            autoComplete='security-code'
          />
        </Grid>
      </Grid>
    </Grid>
  )

  const couponForm = () => (
    <Grid item container>
      <Grid item xs={12}>
        <Link onClick={() => setEnableCoupon(true)} >Apply a coupon</Link>
      </Grid>
      {enableCoupon && (
        <Grid item container>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              id='coupon-code'
              label='Coupon Code'
              autoComplete='coupon-code'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                setEnableCoupon(false)
              }}
            >
              Cancel
          </Button>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Button
              variant='contained'
              color='primary'
            >
              Apply Coupon
          </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )

  return (
    <Card className={classes.card}>
      <CardHeader title={joinTitle}></CardHeader>
      <CardContent>
        <form
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <Grid container >
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='email'
                label='Email'
                autoComplete='email'
                value={email}
                onChange={(e?: any) => { setEmail(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Shipping Address</FormLabel>
            </Grid>
            {addressForm()}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={sameBillingAddress} onChange={e => setSameBillingAddress(e.target.checked)} name="same-billing-address" />}
                label="Use as my Billing Address"
              />
            </Grid>
            {couponForm()}
            <Grid item xs={12}>
              <FormLabel>Select Payment</FormLabel>
            </Grid>
            <FormControl>
              <RadioGroup name="payment-type" value={paymentType} onChange={e => setPaymentType(e.target.value)}>
                <FormControlLabel value="paypal" control={<Radio />} label="Paypal" />
                <FormControlLabel value="creditcard" control={<Radio />} label={paymentForm()} />
              </RadioGroup>
            </FormControl>

            <Grid item xs={12}>
              <FormLabel>Billing Address</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={sameBillingAddress} onChange={e => setSameBillingAddress(e.target.checked)} name="same-billing-address" />}
                label="Same as Shipping Address"
              />
            </Grid>
            {!sameBillingAddress && addressForm()}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='referal'
                label='How did you hear about us? (Optional)'
                autoComplete='referal'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='create-password'
                label='Create Password'
                type='password'
                autoComplete='create-password'
                onChange={e => {
                  setPassword(e.target.value)
                  setError(false)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='confirm-password'
                label='Confirm Password'
                type='password'
                autoComplete='confirm-password'
                onChange={e => {
                  setConfirmPassword(e.target.value)
                  setError(false)
                }}
                helperText={error && "Confirm Password Doesn't match"}
                error={error}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={acceptTerms} onChange={e => setAcceptTerms(e.target.checked)} name="terms" />}
                label="I Accept the Terms and Conditions."
              />
            </Grid>

          </Grid>

          <div className={classes.passwordContainer}>

          </div>
          <div className={classes.passwordContainer}>

          </div>
          <Button
            variant='contained'
            className={classes.confirm}
            color='primary'
            type='submit'
          >
            Join Now
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default Payment
