import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableSortLabel,
  TableRow,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { orderBy } from 'lodash'

// root imports
import { getUsers, getMoreUsers } from 'services/Users'
import {
  HISTORIC_TABLE_HEADERS,
  INVERT_DIRECTION,
  USERS_QUERY_LIMIT
} from 'constants/index'
import miles from 'utils/miles'
import { LoadingOverlay, Search } from 'components'

// local imports
import { HistoricUserRow, DatePicker, ActionsMenu } from './components'

function HistoricUserTotals() {
  const [users, setUsers] = useState([])
  const [lastVisible, setLastVisible] = useState(null)
  const [loading, setLoading] = useState(true)
  const [columnToSort, setColumnToSort] = useState('')
  const [sortDirection, setSortDirection] = useState('desc')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [searchTerm, setSearchTerm] = useState(null)
  const [searchField, setSearchField] = useState('email')

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    th: {
      cursor: 'pointer',
      alignItems: 'center'
    },
    arrows: {
      fontSize: 14,
      cursor: 'pointer'
    },
    actions: {
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    }
  }))

  const classes = useStyles()

  const formatUsers = useCallback(usersData => {
    let formattedUsers = usersData.map(user => {
      user.distance = miles(user.distance)
      user.monthGoal = miles(user.monthGoal)
      return user
    })
    return formattedUsers
  }, [])

  const handleGetMoreUsers = () => {
    const getUsersData = async () => {
      setLoading(true)
      const { usersData, lastVisibleDoc } = await getMoreUsers(
        columnToSort,
        sortDirection,
        lastVisible
      )

      const userList = formatUsers(usersData)

      setLoading(false)
      setLastVisible(lastVisibleDoc)
      setUsers([...users, ...userList])
    }
    getUsersData()
  }

  const handleSearch = e => {
    // we only want to search the database if there search term is at least 3 characters
    if (e.target.value && e.target.value.length > 2)
      setSearchTerm(e.target.value)
    else setSearchTerm(null)
  }

  const handleSearchField = e => {
    setSearchField(e.target.value)
  }

  // run query with sorting
  const handleSort = header => {
    if (header.sorting) {
      setColumnToSort(header.slug)
      setSortDirection(
        columnToSort === header.slug ? INVERT_DIRECTION[sortDirection] : 'asc'
      )
    }
  }

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  // get users on initial page load
  useEffect(() => {
    const getUsersData = async () => {
      setLoading(true)

      const { usersData, lastVisibleDoc } = await getUsers(
        columnToSort,
        sortDirection,
        searchField,
        searchTerm
      )

      const userList = formatUsers(usersData)

      setLoading(false)
      setLastVisible(lastVisibleDoc)
      setUsers(userList)
    }
    getUsersData()
  }, [formatUsers, columnToSort, sortDirection, selectedDate, searchField, searchTerm])

  const sortedUsers = orderBy(users, columnToSort, sortDirection)

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Container maxWidth='xl' className={classes.container}>
        <div className={classes.actions}>
          <Typography>Total Members: {`${sortedUsers.length}`}</Typography>
          <div>
            <DatePicker
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
            />
            <ActionsMenu selectedDate={selectedDate} />
          </div>
        </div>
        <Card className={classes.root}>
          <CardHeader
            title='Historic Member Goals & Totals'
            action={
              <Search
                seachTerm={searchTerm}
                handleSearch={handleSearch}
                searchField={searchField}
                handleSearchField={handleSearchField}
              />
            }
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {HISTORIC_TABLE_HEADERS.map(header => (
                  <TableCell
                    className={classes.th}
                    align={header.slug === 'name' ? 'left' : 'right'}
                    key={header.slug}
                    onClick={() => handleSort(header)}
                    sortDirection={sortDirection}
                  >
                    <TableSortLabel
                      active={columnToSort === header.slug}
                      direction={sortDirection}
                      hideSortIcon={header.sorting ? false : true}
                    >
                      {header.name}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers && sortedUsers.length > 0 ? (
                sortedUsers.map(user => (
                  <HistoricUserRow
                    key={user.id}
                    user={user}
                    selectedDate={selectedDate}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    {loading ? (
                      <div>
                        <CircularProgress size={14} thickness={4} /> Loading...
                      </div>
                    ) : (
                      <div>No members found.</div>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {sortedUsers && sortedUsers.length >= USERS_QUERY_LIMIT ? (
              <TableFooter>
                <TableRow>
                  <TableCell align='center' colSpan={9}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => handleGetMoreUsers()}
                    >
                      {loading ? 'Please wait...' : 'Show More'}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default HistoricUserTotals
