import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    card: {
        width: '800px',
        margin: 'auto',
        marginTop: '100px',
        paddingTop: '2%',
        paddingLeft: '2%',
        paddingRight: '2%'
    },
    password: { width: '100%', margin: 'auto' },
    passwordContainer: { display: 'flex', marginBottom: '20px' },
    confirm: {
        margin: 'auto',
        display: 'block',
        marginTop: '50px',
        width: '130px'
    }
}))

export default useStyles