import React, { useState, useEffect } from 'react'
import { Container, Paper, makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'
import { Delete as DeleteCoach } from '@material-ui/icons'

// root imports
import { coachesCollection } from 'services/firebase'
import { LoadingOverlay } from 'components'
import { COACHES_TABLE_HEADERS } from 'constants/index'

import { AddCoach, EditCoach, ImagesList } from './components'

function Coaches() {
  const [coaches, setCoaches] = useState(null)
  const [loading, setLoading] = useState(false)

  const useStyles = makeStyles(theme => ({
    container: {
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    edit: {
      cursor: 'pointer'
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-around'
    }
  }))

  const classes = useStyles()

  const deleteCoach = async id => {
    await coachesCollection.doc(id).delete()
    getCoaches()
  }

  async function getCoaches() {
    try {
      setLoading(true)
      let coachesArr = []
      const coachesSnapshot = await coachesCollection
        .orderBy('name', 'asc')
        .get()
      coachesSnapshot.forEach(coach =>
        coachesArr.push({ ...coach.data(), id: coach.id })
      )
      setCoaches(coachesArr)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCoaches()
    return
  }, [])

  return (
    <Container maxWidth='xl' className={classes.container}>
      {loading && <LoadingOverlay />}
      <AddCoach onAdd={getCoaches} />
      <Paper className={classes.root}>
        {coaches && !loading && (
          <MaterialTable
            title='Coaches'
            columns={[
              ...COACHES_TABLE_HEADERS,
              {
                field: 'imageUrls',
                title: 'Images',
                render: rowData => (
                  <img
                    src={rowData.imageUrls[0]}
                    alt={rowData}
                    style={{ width: 100, height: 'auto' }}
                  />
                )
              },
              {
                field: 'actions',
                title: 'Actions',
                render: rowData => (
                  <div className={classes.actions}>
                    <EditCoach
                      coach={rowData}
                      onEdit={getCoaches}
                      className={classes.edit}
                    />
                    <DeleteCoach
                      onClick={e => {
                        if (
                          window.confirm(
                            `Are you sure you want to remove coach ${rowData.name}?`
                          )
                        )
                          deleteCoach(rowData.id)
                      }}
                      className={classes.edit}
                    />
                  </div>
                ),
                width: 80
              }
            ]}
            data={coaches}
            options={{
              search: true,
              paging: false
            }}
            detailPanel={[
              {
                icon: 'image',
                tooltip: 'Manage Images',
                render: rowData => {
                  return <ImagesList coach={rowData} />
                }
              }
            ]}
          />
        )}
      </Paper>
    </Container>
  )
}

export default Coaches
