import React, { useState } from 'react'
import moment from 'moment'
import * as yup from 'yup'
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  makeStyles
} from '@material-ui/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { editorModules } from 'constants/index'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-20px'
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `10px 0`,
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  },
  editor: {
    margin: '10px 0 30px',
    width: '100%'
  },
  fileButtonContainer: {
    width: '100%'
  },
  fileButton: {
    margin: `${theme.spacing(1)}px 0`
  },
  fileInput: {
    display: 'none'
  },
  image: {
    width: '200px',
    margin: `${theme.spacing(1)}px 0`
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}))

const AddForm = ({
  isSubmiting,
  onCancel,
  onSave,
  currentInspiration,
  onEdit
}) => {
  const classes = useStyles()

  const initialText =
    '<h1>This is a big header</h1>\n' +
    '<h2>This is a smaller header</h2>\n' +
    '<p>You can also just write text like this. Go to <a href="https://www.99walks.fit">the 99 Walks site</a> if you want to learn more about the movement.</p>\n'

  const initialPlainText = 'This is a sample plain text'

  const [title, setTitle] = useState(
    currentInspiration ? currentInspiration.title : undefined
  )
  const [text, setText] = useState(
    currentInspiration ? currentInspiration.text : initialText
  )
  const [plainText, setPlainText] = useState(
    currentInspiration ? currentInspiration.plainText : initialPlainText
  )
  const [publishDate, setPublishDate] = useState(
    currentInspiration
      ? moment(currentInspiration.publishDate * 1000).format('YYYY-MM-DDTHH:mm')
      : moment().format('YYYY-MM-DDTHH:mm')
  )
  const [image, setImage] = useState({ file: null, preview: null })

  const handleImageChange = event => {
    const reader = new FileReader()
    const file = event.target.files[0]

    reader.onloadend = () => {
      const preview = reader.result
      setImage({ file, preview })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const date = moment(publishDate).unix()
    if (currentInspiration)
      onEdit({ currentInspiration, title, text, plainText, publishDate: date })
    else
      onSave({
        title,
        text,
        plainText,
        publishDate: date,
        imageFile: image.file
      })
  }

  const isTitleValid = yup
    .string()
    .required()
    .isValidSync(title)

  const isTextValid = yup
    .string()
    .required()
    .isValidSync(text)

  const isPlainTextValid = yup
    .string()
    .required()
    .isValidSync(plainText)

  const isPublishDateValid = yup
    .string()
    .required()
    .isValidSync(publishDate)

  const isImageFileValid = !!image.file || currentInspiration

  const canSubmit =
    isTitleValid &&
    isTextValid &&
    isPlainTextValid &&
    isPublishDateValid &&
    isImageFileValid

  return (
    <form
      noValidate
      className={classes.form}
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        margin='normal'
        label='Title'
        placeholder='Enter the inspiration title'
        value={title}
        onChange={e => setTitle(e.target.value)}
        disabled={isSubmiting}
      />

      <FormControl className={classes.formControl} required>
        <FormLabel id='editor-label'>Description</FormLabel>
        <div id='editor-container'>
          <ReactQuill
            className={classes.editor}
            modules={editorModules}
            theme='snow'
            value={text}
            onChange={setText}
            bounds='#editor-container'
          />
        </div>
      </FormControl>

      <TextField
        fullWidth
        multiline
        margin='normal'
        label='Plain Text Description'
        value={plainText}
        rows={7}
        type='textfield'
        onChange={e => setPlainText(e.target.value)}
        disabled={isSubmiting}
        error={plainText === initialPlainText}
        helperText={
          plainText === initialPlainText &&
          'Please set a plain text description.'
        }
      />

      <TextField
        fullWidth
        margin='normal'
        label='Publish Date & Time'
        placeholder='Enter the publish date & time'
        type='datetime-local'
        InputLabelProps={{
          shrink: true
        }}
        value={publishDate}
        onChange={e => setPublishDate(e.target.value)}
        disabled={isSubmiting}
      />

      <input
        accept='image/*'
        className={classes.fileInput}
        id='file-input'
        type='file'
        onChange={handleImageChange}
        disabled={isSubmiting}
      />

      <label htmlFor='file-input' className={classes.fileButtonContainer}>
        <Button
          variant='contained'
          component='div'
          className={classes.fileButton}
        >
          Select Image
        </Button>
      </label>

      {image.preview && (
        <img className={classes.image} alt='inspiration' src={image.preview} />
      )}

      <div className={classes.actions}>
        <Button onClick={onCancel} disabled={isSubmiting}>
          Cancel
        </Button>
        <Button
          variant='contained'
          type='submit'
          color='primary'
          disabled={!canSubmit || isSubmiting}
        >
          {currentInspiration ? 'Save Inspiration' : 'Schedule Inspiration'}
        </Button>
      </div>
    </form>
  )
}

export default AddForm
