import React, { useState } from 'react'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { startCase } from 'lodash'
import * as yup from 'yup'

import { getAdminByEmail } from 'services/LiveClasses'

const LIVE_CLASSES_ADMIN_ROLES = [
  {
    label: 'Host',
    value: 'host'
  },
  {
    label: 'Moderator',
    value: 'moderator'
  },
  {
    label: 'Guest',
    value: 'guest'
  }
]

const initialAdminState = {
  userId: '',
  name: '',
  email: '',
  role: '',
  avatarURL: ''
}

const Admins = ({ classes, loading, admins, onAddAdmin, onDeleteAdmin }) => {
  const [admin, setAdmin] = useState(initialAdminState)
  const [findUserLoading, setFindUserLoading] = useState(false)
  const [errors, setErrors] = useState({ email: '', role: '' })

  const isButtonDisabled =
    loading || findUserLoading || !(admin.email && admin.role)

  const handleAddButtonClick = async () => {
    const { email, role } = admin
    const hasHost = admins.some(admin => admin.role === role)
    const hasAdmin = admins.some(admin => admin.email === email)
    const isEmailValid = await yup
      .string()
      .email()
      .isValid(email)

    if ((role === 'host' && hasHost) || hasAdmin || !isEmailValid) {
      hasHost &&
        setErrors(prev => ({ ...prev, role: 'Only one host is allowed' }))
      hasAdmin &&
        setErrors(prev => ({
          ...prev,
          email: 'This admin has already been added'
        }))
      !isEmailValid &&
        setErrors(prev => ({
          ...prev,
          email: 'Invalid email'
        }))
    } else {
      const foundAdmin = await getAdminByEmail(email, role, setFindUserLoading)

      if (foundAdmin) {
        onAddAdmin(foundAdmin)
        setAdmin(initialAdminState)
      } else {
        setErrors(prev => ({ ...prev, email: 'User not found' }))
      }
    }
  }

  const handleChange = e => {
    const { value, name } = e.target

    setAdmin({ ...admin, [name]: value })
    setErrors(prev => ({ ...prev, [name]: '' }))
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      className={classes.adminsContainer}
    >
      <Typography style={{ opacity: 0.7 }}>Admins *</Typography>

      {admins.map(({ userId, email, role }) => (
        <Grid key={userId} container spacing={2}>
          <Grid item xs={8}>
            <Typography>{email}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{startCase(role)}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size='small'
              onClick={() => onDeleteAdmin(userId, setErrors)}
            >
              <Delete fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Box display='flex' justifyContent='space-between'>
        <TextField
          className={classes.formControlEmail}
          disabled={loading}
          required
          name='email'
          label='Email'
          value={admin.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />

        <FormControl
          className={classes.formControlRole}
          required
          error={!!errors.role}
        >
          <InputLabel>Role</InputLabel>
          <Select
            disabled={loading}
            name='role'
            value={admin.role}
            onChange={handleChange}
          >
            {LIVE_CLASSES_ADMIN_ROLES.map((role, idx) => (
              <MenuItem key={idx} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
          {!!errors.role ? (
            <FormHelperText>{errors.role}</FormHelperText>
          ) : (
            <FormHelperText>Role "Host" is required</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Button
        variant='contained'
        onClick={handleAddButtonClick}
        disabled={isButtonDisabled}
      >
        {findUserLoading ? 'Loading' : 'Add'}
      </Button>
    </Box>
  )
}

export default Admins
