import React from 'react'
import {
  IconButton,
  InputBase,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    margin: '10px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    borderBottom: '1px solid black'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: '5px 2px'
  },
  label: {
    '& span': {
      fontSize: 12,
    }
  }
}))

export default function Search({ searchTerm, handleSearch, searchField, handleSearchField }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup row aria-label="searchfield" name="searchfield" value={searchField} onChange={handleSearchField}>
          <FormControlLabel value="email" className={classes.label} control={<Radio />} label="Email" />
          <FormControlLabel value="name" className={classes.label} control={<Radio />} label="Name" />
        </RadioGroup>
      </FormControl>
      <IconButton
        type='submit'
        className={classes.iconButton}
        aria-label='search'
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={`Search ${searchField}...`}
        inputProps={{ 'aria-label': 'search members' }}
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  )
}
