import { useState } from 'react'
import loadashOrderBy from 'lodash/orderBy'
import { getScheduledAudioClasses } from 'services/AudioClasses'
import { INVERT_DIRECTION } from 'constants/index'

const useScheduledAudioClasses = () => {
  const [loading, setLoading] = useState(true)
  const [scheduledAudioClasses, setScheduledAudioClasses] = useState([])
  const [sortDirection, setSortDirection] = useState('desc')
  const [columnToSort, setColumnToSort] = useState('')

  const getAll = async () => {
    setLoading(true)
    const result = await getScheduledAudioClasses()
    setScheduledAudioClasses(result)
    setLoading(false)
  };

  const orderBy = (column) => {
    setLoading(true)
    const newSortDirection = columnToSort === column
      ? INVERT_DIRECTION[sortDirection]
      : 'asc'
    const sorted = loadashOrderBy(scheduledAudioClasses, column, newSortDirection)
    setScheduledAudioClasses(sorted)
    setColumnToSort(column)
    setSortDirection(newSortDirection)
    setLoading(false)
  }

  return {
    loading,
    sortDirection,
    scheduledAudioClasses,
    getAll,
    orderBy,
  };
};

export default useScheduledAudioClasses;
