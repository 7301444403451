import React, { useState, useEffect } from 'react'
import {
  Container,
  Typography,
  Card,
  CardHeader,
  Switch,
  makeStyles
} from '@material-ui/core'
import { LoadingOverlay } from 'components'

// root imports
import {
  getPushNotificationSettings,
  togglePushNotification
} from 'services/PushNotifications'

// local imports
import NotificationContent from './components/NotificationContent'

const ManagePushNotifications = () => {
  const [pushNotifications, setPushNotifications] = useState([])
  const [loading, setLoading] = useState(true)

  const useStyles = makeStyles(theme => ({
    container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    action: {
      marginTop: '0'
    }
  }))

  const classes = useStyles()

  // get notification settings
  const getNotifications = async () => {
    setLoading(true)
    const notifications = await getPushNotificationSettings()
    setPushNotifications(notifications)
    setLoading(false)
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const toggleNotification = async id => {
    await togglePushNotification(id)
    getNotifications()
  }

  return (
    <React.Fragment>
      {loading && <LoadingOverlay />}
      <Container maxWidth='xl' className={classes.container}>
        <Typography variant='h5' component='h1'>
          Push Notifications
        </Typography>
        {pushNotifications.map(notification => (
          <Card
            key={notification.id}
            classes={{ root: classes.root }}
            variant='outlined'
          >
            <CardHeader
              classes={{ action: classes.action }}
              title={
                <Typography variant='h6' component='h2'>
                  {notification.title}
                </Typography>
              }
              action={
                <Switch
                  checked={notification.enabled}
                  onChange={e => toggleNotification(notification.id)}
                  name={notification.id}
                />
              }
            />
            <NotificationContent id={notification.id} />
          </Card>
        ))}
      </Container>
    </React.Fragment>
  )
}

export default ManagePushNotifications
